import { HelmetTags } from '../../../components/Common/HelmetTags'
import PageContainer from '../../../components/Patient/Auth/PageContainer'

const PatientLogin = () => {
  return (
    <>
      <HelmetTags
        title="Patient Login"
      />
      <PageContainer/>
    </>
  )
}

export default PatientLogin;

