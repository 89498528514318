import DoctorItem from './DoctorItem'

import styles from './style.module.scss'
const DoctorListMain = (props) => {

  const {pageData} = props

  return (
    <div className={styles.listContainer}>
      {pageData?.length > 0 &&
        <>
          {pageData.map(item =>
            <DoctorItem item={item} key={item?.id}/>
          )}
        </>
      }
    </div>
  )
}

export default DoctorListMain;


