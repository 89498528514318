import axios from 'axios'
import { backendUrl } from '../utilities/constants';

const sendContactMessage = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/send_contact_message', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const emailService = {
  sendContactMessage,
}

export default emailService