import styles from './style.module.scss'

const PartLoading = () => {
  return (  
    <div className={styles.loadingContainer}>
      <img src='/images/icons/loader_grey.svg' alt="loading"/>
    </div>
  );
}

export default PartLoading