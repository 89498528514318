import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { backendUrl } from '../utilities/constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

export const fetchAll = createAsyncThunk(
  'service/fetchAll',
  async () => {
    try {
      const response = await axios.get(backendUrl + '/api/get_all_services/'+currentLng)
      return response.data.result
    } catch (err) {
      console.log(err);
    }
  }
);

export const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    items: [],
    loading: false,
    error: false,
    message: ''
  },

  extraReducers: {

    [fetchAll.pending]: (state, action) => {
      state.loading = true
    },
    [fetchAll.fulfilled]: (state, action) => {
      state.error = false
      state.message = 'Successed'
      state.loading = false
      state.items = action.payload;
    },
    [fetchAll.rejected]: (state, action) => {
      state.error = true
      state.message = 'faild'
      state.loading = false
    }

  },
});

export default servicesSlice.reducer;
