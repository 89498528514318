import React, {useState} from 'react';
import { useForm} from "react-hook-form";
import { useTranslation } from '../../../hooks/useTranslation';
import { CommonBtn } from '../../Common/CSTButton'
import { useSelector, useDispatch } from 'react-redux';
import { updateAuth } from "../../../store/auth";
import authService from '../../../services/authService';
import PartLoading from "../../Common/PartLoading";

import styles from './style.module.scss'

const EmailChanging = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  const { auth } = useSelector(store => store.auth)
  const { handleSubmit, register, getValues, formState: { errors } } = useForm({})

  const submitData = async (data) => {
    setLoading(true)
    const res = await authService.userUpdate(data);
    dispatch(updateAuth(res.result));
    setResponse(res)
    setLoading(false)
  }

  const onSubmit = (data) => {
    const newData  = {
      token: auth?.token,
      id: auth?.id,
      email: data.new_email,
    }
    submitData(newData);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      {loading && <PartLoading />}
      <div className={styles.formTitle}>
        {t('Change E-Mail address')}
      </div>
      {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}
      {response.success && response.result && <div className='alert alert-success' role='alert'>{t('Changing is successed')}</div>}

      <div className='row mb-2'>
        <div className='col-lg-3 col-md-4 col-12'>
          <label className="text-start form-label">{t('Current email')}: </label>
        </div>
        <div className='col-lg-6 col-md-7 col-12'>
          <input type="email" className="form-control smInput" disabled value={auth?.email} />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-lg-3 col-md-4 col-12'>
          <label className="text-start form-label">{t('New email')}: </label>
        </div>
        <div className='col-lg-6 col-md-7 col-12'>
          <input type="email" className="form-control smInput"
            {...register("new_email", { required: `${t('Field is required')}` })}
          />
          <div className="text-danger">{errors?.new_email?.message}</div>
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-lg-3 col-md-4 col-12'>
          <label className="text-start form-label">{t('Confirm new email')}: </label>
        </div>
        <div className='col-lg-6 col-md-7 col-12'>
          <input type="email" className="form-control smInput"
            {...register("re_email", {
              required: `${t('Field is required')}`,
              validate: value =>
                value === getValues('new_email') || t('Email is not matched!')
            })}
          />
          <div className="text-danger">{errors?.re_email?.message}</div>
        </div>
      </div>
      <CommonBtn type="submit" color="primary" borderRadius size="small">
        {t('Change E-Mail address')}
      </CommonBtn>

    </form>
  );
}

export default EmailChanging;