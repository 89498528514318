import { HelmetTags } from '../../../components/Common/HelmetTags'
import PageContainer from '../../../components/Patient/Register/PageContainer'

const PatientRegister = () => {
  return (
    <>
      <HelmetTags
        title="Patient Register"
      />
      <PageContainer/>
    </>
  )
}

export default PatientRegister;

