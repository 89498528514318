import React, { useState, useEffect } from 'react';
import { useTranslation } from "../../hooks/useTranslation";
import { useParams } from 'react-router-dom'
import PageBackGround from '../Common/PageBackGround';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import Loading from '../Common/Loading'

import authService from '../../services/authService';

import styles from './style.module.scss'

const PageContainer = () => {
  const { t } = useTranslation()
  const { time, id } = useParams();
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const activeEmail = async () => {
    setLoading(true)
    const res = await authService.activeEmail(time, id);
    setResponse(res);
    setLoading(false)
  }

  useEffect(() => {
    activeEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    loading
      ? <Loading />
      : 
      <>
        <PageBackGround>
          <h1>{t('Email Activation')}</h1>
        </PageBackGround>
        <div className="container">
          <div className={styles.ActivaEmailPageContainer}>
            {
              !response.success && response.result &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                <AiOutlineCloseCircle className="me-2" size="20" />
                {t(response.result)}
              </div>
            }
            {
              response.success && response.result &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                <AiOutlineCheckCircle className="me-2" size="20" />
                {t('Activation was successful!')}
              </div>

            }
          </div>
        </div>
      </>
  )
}

export default PageContainer;


