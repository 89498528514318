import React, { useState, useEffect} from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import expertiseService from '../../../services/expertiseService';

const ExpertiseDropdown = (props) => {
  const {t} = useTranslation()
  const {selected, onChange} = props
  const [data, setData] = useState([])

  const getAllExpertises = async () => {
    const res = await expertiseService.getExpertises();
    if(res.success&&res.result) setData(res.result);
  }
  
  useEffect(()=>{
    getAllExpertises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (  
    <select className='form-control smInput' value={selected} onChange={(e)=>onChange(e.target.value)}>
      <option value="">{t('Select Expertise')}</option>
      { data?.length>0 && data?.map((item) => 
          <option value={item.id} key={item.id}>{item?.name}</option>
      )}
    </select>
  );
}
 
export default ExpertiseDropdown;