import { useTranslation } from "../../hooks/useTranslation";
import { Link } from 'react-router-dom'
import PageBackGround from '../Common/PageBackGround';
import styles from './style.module.scss'
import { CommonBtn } from "../Common/CSTButton";

const PageContainer = () => {
  const {t} = useTranslation()
  return (
    <>
      <PageBackGround>
        <h1>{t('Error 404')}</h1>
      </PageBackGround>
      <div className="container">
        <div className={styles.pageContainer}>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <img src="/images/img/404.jpg" alt="404 page"  className={styles.image404}/>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className={styles.textWraper}>
                <h1>404</h1>
                <h4>{t('Ooops! That page doesn\'t exist!')}</h4>
                <div className={styles.content}>
                  {t('This-Spelling')}
                </div>
                <Link to='/'>
                    <CommonBtn 
                      color="primary" 
                      borderRadius 
                      uppercase 
                      ariaLabel={t('Back to Home')}
                    >
                      {t('Back to Home')}
                    </CommonBtn>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageContainer;


