import { useTranslation } from "../../hooks/useTranslation";
import PageBackGround from '../Common/PageBackGround';
import WorkProcess from '../Common/WorkProcess'
import WhyWe from "../Home/WhyWe";
import OurTeam from './OurTeam'

const PageContainer = () => {
  const {t} = useTranslation()
  return (
    <>
      <PageBackGround>
        <h1>{t('About Us')}</h1>
      </PageBackGround>
      <WorkProcess/>
      <WhyWe/>
      <OurTeam/>
    </>
  )
}

export default PageContainer;


