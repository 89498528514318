import { useLocation } from 'react-router-dom'
import { HelmetTags } from '../../../components/Common/HelmetTags'
import PageContainer from '../../../components/Doctor/Register/PageContainer'

const Register = () => {
  const { state } = useLocation()

  return (
    <>
      <HelmetTags
        title="Doctor Reigstration"
      />
      <PageContainer doctorType={state}/>
    </>
  )
}

export default Register;

