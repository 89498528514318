import { HelmetTags } from '../../components/Common/HelmetTags'
import PageContainer from '../../components/Contact/PageContainer'

const ContactUs = () => {
  return (
    <>
      <HelmetTags
        title="Contact Us"
      />
      <PageContainer/>
    </>
  )
}

export default ContactUs;

