import { useTranslation } from "../../../../hooks/useTranslation"
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { HiOutlineChevronDown } from "react-icons/hi"

import styles from './style.module.scss'

const AsideAccodian = (props) => {
  const { t } = useTranslation()
  const { item, handleClose } = props;
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  const handleChange = (href) => {
    if(href!==''){
      navigate(href)
      handleClose();
    }
    setIsActive(prev => !prev);
  }

  return (
    <li className={`${styles.mainMenuItem} ${isActive ? styles.active : ''}`} >
      <div className={styles.menuTitle} onClick={()=>handleChange(item.url)}>
          {t(item.title)}
          {item?.submenu?.length && <HiOutlineChevronDown size="20" />}
      </div>
      <ul className={styles.subMenu}>
        {
          item?.submenu?.length > 0 && item.submenu.map((menu, ind) => (
            <li className={styles.menuItem} key={ind}>
              <Link to={menu.url} onClick={handleClose}>{t(menu.title)}</Link>
            </li>
          ))
        }
      </ul>
    </li>
  );
}

export default AsideAccodian;