import { useSelector } from 'react-redux';

import {FiMail, FiMapPin} from "react-icons/fi";
import {FaFacebookF, FaTwitter, FaLinkedinIn, FaGoogle} from "react-icons/fa";

import styles from './style.module.scss'

const HeaderTop = () => {

  const admin = useSelector(store => store.admin.admin);

  return (
    <div className={styles.headerTopContainer}>
      <ul>
        <li className={styles.headerContact}>
          <FiMail size="16"/>
          {admin?.email}
        </li>
        <li className={styles.headerContact}>
          <FiMapPin size="16"/>
          {`${admin?.state}, ${admin?.city}, ${admin?.zip}`}
        </li>
      </ul>
      <ul>
        <li className={styles.headerSocial}>
          <FaFacebookF size="18"/>
        </li>
        <li className={styles.headerSocial}>
          <FaTwitter size="18"/>
        </li>
        <li className={styles.headerSocial}>
          <FaLinkedinIn size="18"/>
        </li>
        <li className={styles.headerSocial}>
          <FaGoogle size="18"/>
        </li>
      </ul>
    </div>
  )
}

export default HeaderTop;