import { useTranslation } from "../../../hooks/useTranslation";
import PageBackGround from '../../Common/PageBackGround';
import RegisterContainer from './RegisterContainer.js';
import AreYouDoctor from "../Auth/AreYouDoctor";

const PageContainer = () => {
  const {t} = useTranslation()
  return (
    <>
      <PageBackGround>
        <h1>{`${t('Login')} / ${t('Registration')}`}</h1>
      </PageBackGround>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-8">
            <RegisterContainer/>
          </div>
          <div className="col-lg-4">
            <AreYouDoctor/>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageContainer;


