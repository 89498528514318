import { useTranslation } from "../../../hooks/useTranslation"
import {IoMailOpenOutline} from "react-icons/io5"
import {MdOutlineMarkEmailRead} from "react-icons/md"
import {CommonBtn} from '../../Common/CSTButton'
import styles from './style.module.scss'

const SubScribe = () => {
  const {t} = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div className={styles.subscribeContainer}>
      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className={styles.subFormContainer}>
            <div>
              <div className={styles.formHeader}>
                <IoMailOpenOutline/>
                <h4>{t('Subscribe Our Email For Newsletter')}</h4>
              </div>
              <p>{t('It-layout')}</p>
            </div>
            <form onSubmit={handleSubmit} className={styles.formWraper}>
              <input className="form-control noBorder" type='text' name='subscribe_email' placeholder={t('Email address')} />
              <CommonBtn color="white" type="submit" borderRadius ariaLabel={t('Subscribe')}>
                <MdOutlineMarkEmailRead size="18"/>
                <div className="ms-2">
                  {t('Subscribe')}
                </div>
              </CommonBtn>
            </form>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className={styles.iconContainer}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <div className={styles.subsIconWraper}>
                  <img src='/images/icons/subscribe-1.png' alt="subscribe1" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                <div className={styles.subsIconWraper}>
                  <img src='/images/icons/subscribe-2.png' alt="subscribe2" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className={styles.subsIconWraper}>
                  <img src='/images/icons/subscribe-3.png' alt="subscribe3" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className={styles.subsIconWraper}>
                  <img src='/images/icons/subscribe-4.png' alt="subscribe4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubScribe;