import { useTranslation } from "../../../hooks/useTranslation";

import styles from './style.module.scss'
const DoctorListSide = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle}>
          {t('Specialty')}
        </div>
        <div className={styles.sectionContent}>
          <div className={`${styles.specialItem} ${styles.sectionItem}`}>
            Distinations
          </div>
          <div className={`${styles.specialItem} ${styles.sectionItem}`}>
            Hotels
          </div>
          <div className={`${styles.specialItem} ${styles.sectionItem}`}>
            News
          </div>
          <div className={`${styles.specialItem} ${styles.sectionItem}`}>
            StartUp
          </div>
          <div className={`${styles.specialItem} ${styles.sectionItem}`}>
            Strategy
          </div>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle}>
          {t('Apppointment availability')}
        </div>
        <div className={styles.sectionContent}>
          <div className={styles.sectionItem}>
            <input type="checkbox" id="free_doctor" />
            <label htmlFor="free_doctor">
              Free doctors only
            </label>
          </div>
          <div className={styles.sectionItem}>
            <input type="checkbox" id="unavailable_doctor" />
            <label htmlFor="unavailable_doctor">
              Unavailable doctors only
            </label>
          </div>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.sectionTitle}>
          {t('Gender')}
        </div>
        <div className={styles.sectionContent}>
          <div className={styles.sectionItem}>
            <input type="radio" id="no_preference" name="gender" />
            <label htmlFor="no_preference">
              No Preference
            </label>
          </div>
          <div className={styles.sectionItem}>
            <input type="radio" id="female" name="gender" />
            <label htmlFor="female">
              {t('Female')}
            </label>
          </div>
          <div className={styles.sectionItem}>
            <input type="radio" id="male" name="gender" />
            <label htmlFor="male">
              {t('Male')}
            </label>
          </div>
        </div>
      </div>

    </>
  )
}

export default DoctorListSide;


