import { useSelector } from "react-redux"

export const useTranslation = () => {
  const { texts } = useSelector(store => store.texts)

  const t = (st) => {
    return texts && texts[st] ? texts[st] : st
  }

  return { t }
}