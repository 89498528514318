import { useTranslation } from "../../../hooks/useTranslation"
import { RiHeartsLine } from "react-icons/ri";
import { HiOutlineUserGroup } from "react-icons/hi";
import Counter from "../Counter";

import styles from './style.module.scss'
const WhyWe = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.whyWeWraper}>
      <div className="container">
        <div className={`row ${styles.whyweRow}`}>

          <div className="col-lg-5">
            <h3>{t('Why Choose Docfind Clinic?')}</h3>
            <p>{t('Patients-that')}</p>

            <div className={styles.whyweItem}>
              <div className="me-3">
                <RiHeartsLine size="40" />
              </div>
              <div>
                <h5 className="mb-3">{t('Quality Control System')}</h5>
                <p>{t('It-content')}</p>
              </div>
            </div>

            <div className={styles.whyweItem}>
              <div className="me-3">
                <HiOutlineUserGroup size="40" />
              </div>
              <div>
                <h5 className="mb-3">{t('Highly professional staff')}</h5>
                <p>{t('It-content')}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 offset-lg-1">
            <div className={styles.whyImgContainer}>
              <img src='/images/img/why-we-1.jpg'
                alt="whywe1" className={`${styles.imageItem}`}
              />
              <img src='/images/img/why-we-2.jpg'
                alt="whywe2" className={`${styles.imageItem} ${styles.secondImg}`}
              />
            </div>
          </div>
        </div>

        <Counter />
      </div>
    </div>
  )
}

export default WhyWe