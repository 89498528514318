import { HelmetTags } from '../../../components/Common/HelmetTags'
import PageContainer from '../../../components/Doctor/Home/PageContainer'

const Home = () => {
  return (
    <>
      <HelmetTags
        title="Doctor"
      />
      <PageContainer />
    </>
  )
}

export default Home;

