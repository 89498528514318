import React, { useState } from 'react';
import { useTranslation } from "../../../hooks/useTranslation";
import reactStringReplace from 'react-string-replace';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { CommonBtn } from "../../Common/CSTButton";
import Loading from '../../Common/Loading';
import styles from '../Auth/style.module.scss'
import authService from '../../../services/authService';

const RegisterContainer = () => {
  const { t } = useTranslation();
  const { register, getValues, handleSubmit, formState: { errors } } = useForm({});

  const [ loading, setLoading ] = useState(false)
  const [ response, setResponse ] = useState({})

  const onSubmit = async (data) => {
    setLoading(true)
    data.status = 0
    const res = await authService.userRegister(data);
    setResponse(res);
    setLoading(false)
  }

  const repalaceText = () => {
    let st = t('I-Policy').replace('<domain>', window.location.hostname);
    st = reactStringReplace(st, '<Policy>', (i) => (
      <Link key={i} to='/page/privacy'>{t('Policy')}</Link>
    ));
    st = reactStringReplace(st, '<Conditions>', (i) => (
      <Link key={i} to='/page/faqs'>{t('Conditions')}</Link>
    ));
    st = reactStringReplace(st, '<Data protection>', (i) => (
      <Link key={i} to='/page/terms'>{t('Data protection')}</Link>
    ));
    return st
  }

  return (
    loading
      ? <Loading />
      :
      <div className={styles.loginWraper}>
        <h5 className="mb-3">{t('Registration as a new user')}</h5>

        <form onSubmit={handleSubmit(onSubmit)}>
          {response.success && response.result && <div className='alert alert-success' role='alert'>{t('Please-functions')}</div>}
          {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}
          <div className="row mb-3">
            <div className="col-lg-4 col-md-4">
              <label className="text-end d-block">
                {t('Gender')} :
              </label>
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="d-flex">
                <div className="me-3">
                  <input type="radio" id="female" value="female"
                    {...register("gender", { required: `${t('Field is required')}` })}
                  />
                  <label htmlFor="female">
                    {t('Female')}
                  </label>
                </div>
                <div className="me-2">
                  <input type="radio" id="male" value="male"
                    {...register("gender", { required: `${t('Field is required')}` })}
                  />
                  <label htmlFor="male">
                    {t('Male')}
                  </label>
                </div>
              </div>
              <div className="text-danger">{errors?.gender?.message}</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 col-md-4">
              <label className="form-label" htmlFor="email">
                {`${t('Your')} ${t('Email address')}`} :
              </label>
            </div>
            <div className="col-lg-8 col-md-8">
              <input className="form-control smInput" type="email" id="email"
                {...register("email", { required: `${t('Field is required')}` })}
              />
              <div className="text-danger">{errors?.email?.message}</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 col-md-4">
              <label className="form-label" htmlFor="password">
                {`${t('Your')} ${t('Password')}`} :
              </label>
            </div>
            <div className="col-lg-8 col-md-8">
              <input className="form-control smInput" type="password" id="password"
                {...register("password", {
                  required: `${t('Field is required')}`,
                  minLength: {
                    value: 6,
                    message: `${t('Password requires at least 6 charactors')}`
                  }
                })}
              />
              <div className="text-danger">{errors?.password?.message}</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-4 col-md-4">
              <label className="form-label" htmlFor="c_password">
                {t('Wiederholung Ihres Passwortes')}:
              </label>
            </div>
            <div className="col-lg-8 col-md-8">
              <input className="form-control smInput" type="password" id="c_password"
                {...register("c_password", {
                  required: `${t('Field is required')}`,
                  minLength: {
                    value: 6,
                    message: `${t('Password requires at least 6 charactors')}`
                  },
                  validate: value =>
                    value === getValues('password') || t('The-match')
                })}
              />
              <div className="text-danger">{errors?.c_password?.message}</div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="offset-lg-4 offset-md-4 col-lg-8 col-md-8">
              <input type="checkbox" id="req1" required />
              <label htmlFor="req1" className="font-14 lh-1">
                {repalaceText()}
              </label>
            </div>
          </div>

          <div className="row mb-3">
            <div className="offset-lg-4 offset-md-4 col-lg-8 col-md-8">
              <input type="checkbox" id="req2" required />
              <label htmlFor="req2" className="font-14 lh-1">
                {t('I-parties').replace('<domain>', window.location.hostname)}
              </label>
            </div>
          </div>
          <input className="form-control" type="hidden" value="patient" {...register("role")} />

          <div className="row">
            <div className="offset-lg-4 offset-md-4 col-lg-8 col-md-8">
              <CommonBtn color="success" type="submit" uppercase borderRadius
                ariaLabel="register"
              >
                {t('Register')}
              </CommonBtn>
            </div>
          </div>
        </form>

      </div>
  );
}

export default RegisterContainer;