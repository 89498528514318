import styles from './style.module.scss'

const Modal = (props) => {
  const { open } = props
  return (
    <>
      {open ?
        <div className={styles.modalWraper}>
          <div className={styles.modal}>
            {props.children}
          </div>
        </div>
        : null
      }
    </>
  );
}

export default Modal;