import { useTranslation } from "../../../../hooks/useTranslation";
import {Link} from 'react-router-dom'

import styles from './style.module.scss'

const CopyRight = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.copyRightWrap}>
      <div className="mb-3">
        ©{t('Website')} <span className={styles.copyRightYear}>2022</span> | {t('All Rights Reserved')}
      </div>
      <div className={styles.CopyRightMenus}>
        <Link to="/page/privacy">
          {t('Privacy')}
        </Link>
        <Link to="/page/terms">
          {t('Terms')}
        </Link>
        <Link to="/page/sitemap">
          {t('Sitemap')}
        </Link>
        <Link to="/page/help">
          {t('Help')}
        </Link>
      </div>
    </div>
  );
}

export default CopyRight;