import styles from "./style.module.scss";

const PageBackGround = (props) => {
  return (  
    <div className={styles.pageBGContainer}>
      {props.children}
    </div>
  );
}
 
export default PageBackGround;