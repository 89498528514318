import { useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import authService from '../../../services/authService';
import { userLogout } from '../../../store/auth';
import { useNavigate } from 'react-router-dom';

const CheckLoggedin = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { auth } = useSelector(store => store.auth)

  const check_loggin = async () => {
    const res = await authService.checkLoggedin(auth?.token)
    if (!res.success && !res.result) {
      dispatch(userLogout(auth?.token))
    }
    if(auth?.role !== 'patient'){
      navigate('/');
    }
  }

  useEffect(()=> {
    check_loggin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (  
    props.children
  );
}
 
export default CheckLoggedin;