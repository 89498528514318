import { Link } from 'react-router-dom'
import { BiMap } from "react-icons/bi";
import { backendUrl } from '../../../utilities/constants';

import styles from './style.module.scss'

const DoctorSlideItem = (props) => {
  const { item } = props

  return (
    <div className={styles.slideContainer}>
      <div className={styles.imageWraper}>
        <img
          src={
            item?.photo
              ? backendUrl + '/images/profile/' + item?.photo + '?v=' + new Date().getTime()
              : './images/img/user_black.jpg'
          }
          alt={item?.first_name}

        />
      </div>
      <div className={styles.slideContent}>
        <div className={styles.doctorName}>
          <Link to={`/doctor-detail/${item.id}`}>
            {item?.first_name + item?.last_name}
          </Link>
        </div>
        <div className={styles.doctorExpertise}>
          <Link to={`/doctor-detail/${item.id}`}>
            {item?.expertise}
          </Link>
        </div>
        <div className={styles.userCountryContainer}>
          <BiMap size="20" className='me-2' />
          {item?.country}
        </div>
      </div>
    </div>
  )
}

export default DoctorSlideItem;