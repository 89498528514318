import { HelmetTags } from '../../components/Common/HelmetTags'
import PageContainer from '../../components/ActiveEmail/PageContainer'

const ActiveEmail = () => {
  return (
    <>
      <HelmetTags
        title="Email Activation"
      />
      <PageContainer/>
    </>
  )
}

export default ActiveEmail;

