import Contact from './Contact'
import Menus from './Menus';
import CopyRight from './CopyRight';

import styles from './style.module.scss'

const Footer = () => {
  return ( 
    <div className={styles.footerWrap}>
      <div className='container'>
        <Contact/>
        <Menus/>
        <CopyRight/>
      </div>
    </div>
  );
}

export default Footer;