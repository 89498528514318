import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from '../../../hooks/useTranslation';
import { CommonBtn } from '../../Common/CSTButton'
import { useSelector, useDispatch } from 'react-redux';
import { updateAuth } from "../../../store/auth";
import authService from '../../../services/authService';
import Switch from "react-switch";
import PartLoading from "../../Common/PartLoading";

import styles from './style.module.scss'

const Notifications = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  const { auth } = useSelector(store => store.auth)

  const { handleSubmit, control } = useForm({
    defaultValues: {
      monthly_news: parseInt(auth?.monthly_news) === 1 ? true : false,
      email_notify: parseInt(auth?.email_notify) === 1 ? true : false
    }
  })

  const submitData = async (data) => {
    setLoading(true)
    const res = await authService.userUpdate(data);
    dispatch(updateAuth(res.result));
    setResponse(res)
    setLoading(false)
  }

  const onSubmit = (data) => {
    const newData = {
      token: auth?.token,
      id: auth?.id,
      monthly_news: data?.monthly_news ? 1 : 0,
      email_notify: data?.email_notify ? 1 : 0
    }
    submitData(newData);
  }
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      {loading && <PartLoading />}
      <div className={styles.formTitle}>
        {t('Notifications')}
      </div>
      {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}
      {response.success && response.result && <div className='alert alert-success' role='alert'>{t('Changing is successed')}</div>}

      <div className='row mb-2'>
        <div className='col-lg-4 col-md-8 col-12'>
          <label className="ps-0">{t('Monthly health newsletter?')}: </label>
        </div>
        <div className='col-lg-6 col-md-4 col-12 mt-2'>
          <Controller
            name='monthly_news'
            control={control}
            render={({
              field: { value, onChange }
            }) => (
              <Switch
                onChange={onChange}
                checked={value}
                width={50}
                height={20}
              />
            )}
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-lg-4 col-md-8 col-12'>
          <label className="ps-0">{t('Received other emails?')}: </label>
        </div>
        <div className='col-lg-6 col-md-4 col-12 mt-2'>
          <Controller
            name='email_notify'
            control={control}
            render={({
              field: { value, onChange }
            }) => (
              <Switch
                onChange={onChange}
                checked={value}
                width={50}
                height={20}
              />
            )}
          />
        </div>
      </div>
      <CommonBtn type="submit" color="primary" borderRadius size="small">
        {t('Change settings')}
      </CommonBtn>
    </form>
  );
}

export default Notifications;