import { HelmetTags } from '../../../components/Common/HelmetTags'
import CheckLoggedin from '../../../components/Patient/Auth/CheckLoggedin'
import PageContainer from '../../../components/Patient/Reviews/PageContainer'

const Reviews = () => {
  return (
    <CheckLoggedin>
      <HelmetTags
        title="My Reviews"
      />
      <PageContainer/>
    </CheckLoggedin>
  )
}

export default Reviews;