import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../../hooks/useTranslation";
import { FiMapPin } from "react-icons/fi";
import SelectSearch from "react-select-search";
import { useSelector } from 'react-redux';
import 'react-select-search/style.css'

const LocationSearch = (props) => {
  const {searchRequest, setSearchRequest} = props;
  const { t } = useTranslation()
  const [options, setOptions] = useState([
    { name: t('No selection'), value: ''},
    {
      type: 'group',
      name: t('State'),
      items: []
    },
    {
      type: 'group',
      name: t('Post code'),
      items: []
    },
    {
      type: 'group',
      name: t('City'),
      items: []
    },
    {
      type: 'group',
      name: t('Street'),
      items: []
    },
  ])

  const doctors = useSelector(store => store.doctors.items)

  const getOptions = async () => {
    const newData = [...options]
    const stateArr = []
    doctors.forEach(item => {
      const found = stateArr.find(obj => obj.name === item.street)
      if(!found) stateArr.push({name: item.state, value: item.state})
    })

    const postalArr = []
    doctors.forEach(item => {
      const found = postalArr.find(obj => obj.name === item.street)
      if(!found) postalArr.push({name: item.zip, value: item.zip})
    })

    const cityArr = []
    doctors.forEach(item => {
      const found = cityArr.find(obj => obj.name === item.street)
      if(!found) cityArr.push({name: item.city, value: item.city})
    })

    const streetArr = []
    doctors.forEach(item => {
      const found = streetArr.find(obj => obj.name === item.street)
      if(!found) streetArr.push({name: item.street, value: item.street})
    })

    newData.forEach((item, ind) => {
      if(item.name === t('State')){
        newData[ind].items = stateArr
      }
      if(item.name === t('Post code')){
        newData[ind].items = postalArr
      }
      if(item.name === t('City')){
        newData[ind].items = cityArr
      }      
      if(item.name === t('Street')){
        newData[ind].items = streetArr
      }
    })

    setOptions(newData)
  }

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return options;
      }
      const updatedItems = items.map((list) => {
        const newItems = list?.items?.filter((item) => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        return { ...list, items: newItems };
      });
      return updatedItems;
    };
  };

  const handleChange = (...args) => {
    if(args[1].groupName === t('State')){
      setSearchRequest({
        ...searchRequest,
        locationCt: 'state',
        locationValue: args[1].value
      })
    }
    if(args[1].groupName === t('Post code')){
      setSearchRequest({
        ...searchRequest,
        locationCt: 'zip',
        locationValue: args[1].value
      })
    }
    if(args[1].groupName === t('City')){
      setSearchRequest({
        ...searchRequest,
        locationCt: 'city',
        locationValue: args[1].value
      })
    }
    if(args[1].groupName === t('Street')){
      setSearchRequest({
        ...searchRequest,
        locationCt: 'street',
        locationValue: args[1].value
      })
    }
  };

  return (
    <div className="flex-grow-1 d-flex position-relative h-100">
      <FiMapPin size="18" className="inIcon leftIcon" />
      <SelectSearch
        name="location"
        options={options}
        filterOptions={handleFilter}
        value={searchRequest.locationValue}
        search
        placeholder={t('State') + ', ' + t('Post code') + ', '+t('City')+ ', '+t('Street')}
        onChange={handleChange}
      />
    </div>
  );
}

export default LocationSearch;