import { useTranslation } from "../../../hooks/useTranslation";
import { Link } from 'react-router-dom'
import moment from "moment";
import { AiOutlineHeart, AiOutlineCalendar, AiOutlineStar, AiFillStar } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { TbAward } from "react-icons/tb";
import { CommonBtn, OutLineBtn } from '../../Common/CSTButton'
import { backendUrl } from "../../../utilities/constants";

import styles from './style.module.scss'

const DoctorItem = (props) => {
  const { t } = useTranslation()
  const { item } = props

  return (
    <div className={styles.doctorItemContainer}>
      <div className="row noMargin">
        <div className="col-md-3">
          <div className={styles.imageWraper}>
            <img
              src={
                item?.photo
                  ? backendUrl + '/images/profile/' + item?.photo + '?v=' + new Date().getTime()
                  : './images/img/user_black.jpg'
              }
              alt={item?.name_en}
            />
          </div>
        </div>
        <div className="col-md-5 col-sm-6">
          <div className={styles.itemBody}>
            <h5>
              <Link to={`/doctor-detail/${item.id}`}>
                {item?.first_name + ' ' + item?.last_name}
              </Link>
            </h5>
            <div className={styles.expertise}>
              <Link to={`/doctor-detail/${item.id}`}>
                {item?.expertise}
              </Link>
            </div>
            <div className={styles.school}>
              {item?.school}
            </div>
            <div className={styles.buttonWraper}>
              <Link to={`/doctor-detail/${item.id}`}>
                <CommonBtn color="success" size="small" borderRadius uppercase
                  ariaLabel={t('View More')}
                >
                  {t('View More')}
                </CommonBtn>
              </Link>
              <OutLineBtn color="success" size="small" borderRadius uppercase
                ariaLabel="Favorite"
              >
                <AiOutlineHeart size="22" />
              </OutLineBtn>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6">
          <div className={styles.itemRight}>
            <div className={styles.rightWraper}>
              <div className={styles.rightMain}>
                {item?.country &&
                  <div className={styles.rightItem}>
                    <BiMap size="20" />
                    {item?.country}
                  </div>
                }
                {item?.experience &&
                  <div className={styles.rightItem}>
                    <TbAward size="20" />
                    {`${item?.experience} ${t('Yrs Experience')}`}
                  </div>
                }
                <div className={styles.rightItem}>
                  <AiOutlineCalendar size="20" />
                  {moment(new Date(item?.created_at)).format('LL')}
                </div>
              </div>

              <div className={styles.review}>
                {
                  [...Array(5).keys()].map(i => {
                    return i < 3 ? <AiFillStar key={i} size="20" />
                      : <AiOutlineStar key={i} size="20" />
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoctorItem;


