import { configureStore } from '@reduxjs/toolkit';

import textsSlice from './texts';
import authReducer from './auth';
import configsSlice from './configs';
import servicesSlice from './services';
import doctorsSlice  from './doctors';
import blogsSlice  from './blogs';
import adminDetailSlice from './adminDetail';
import slidersSlice from './sliders';

const store = configureStore({
    reducer: {
      texts: textsSlice,
      auth: authReducer,
      admin: adminDetailSlice,
      configs: configsSlice,
      services: servicesSlice,
      doctors: doctorsSlice,
      blogs: blogsSlice,
      sliders: slidersSlice,
    },
});

export default store;