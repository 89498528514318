import { HelmetTags } from '../../../components/Common/HelmetTags'
import PageContainer from '../../../components/Patient/Auth/ForgotWraper'

const Forgot = () => {
  return (
    <>
      <HelmetTags
        title="Forgot Password"
      />
      <PageContainer/>
    </>
  )
}

export default Forgot;

