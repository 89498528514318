import Greeting from './Greeting'
import Mydates from './Mydates'
import MyReviews from './MyReviews'
import AboutMe from './AboutMe'
import styles from './style.module.scss'

const PageContainer = () => {
  return (
    <div className={styles.homeContainer}>
      <Greeting/>
      <Mydates/>
      <MyReviews/>
      <AboutMe/>
    </div>
  );
}

export default PageContainer;