import { HelmetTags } from '../../../components/Common/HelmetTags'
import PageContainer from '../../../components/Patient/Dates/PageContainer'
import CheckLoggedin from '../../../components/Patient/Auth/CheckLoggedin'

const Dates = () => {
  return (
    <CheckLoggedin>
      <HelmetTags
        title="My dates"
      />
      <PageContainer/>
    </CheckLoggedin>
  )
}

export default Dates;

