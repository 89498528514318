import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { getCoordinates } from '../../../hooks/googleMap';

import styles from './style.module.scss'

const Marker = ({ text }) => 
    <img src='./images/icons/map_marker.png' 
      alt={text}
      className={styles.markIcon}
    />

const AddressMap = () => {
  const configs = useSelector(store => store.configs.configs);
  const admin = useSelector(store => store.admin.admin);
  const [pos, setPos] = useState({});
  
  useEffect(()=>{
    const getCood = async () => {
      if(Object.keys(admin).length>0){
        const address = admin.city + ', '+ admin.zip;
        setPos(await getCoordinates(address, configs.google_map_api))
      }
    }
    getCood();
  }, [admin, configs])

  return (
    <div className={styles.mapWraper}>
      { Object.keys(admin)?.length>0 && Object.keys(pos)?.length>0 ?
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: configs.google_map_api }}
          zoom={7}
          center={pos}
          >
            <Marker
              lat={pos.lat}
              lng={pos.lng}
              text="My Marker"
            />
        </GoogleMapReact>
        :
        <div className={styles.mapLoading}>
          <img src='/images/icons/loader_grey.svg' alt='loading'/>
        </div>

      }
    </div>
  )
}

export default AddressMap;


