import { useTranslation } from "../../../../hooks/useTranslation";
import { Link } from 'react-router-dom'
import { backendUrl } from "../../../../utilities/constants";

import styles from './style.module.scss'

const LogoContainer = () => {
  const { t } = useTranslation();
  
  return (
    <Link to="/">
      <img
        src={backendUrl + '/images/site_logo.png?v=' + new Date().getTime()}
        alt={t('Site Logo')}
        className={styles.logo}
      />
    </Link>
  );
}

export default LogoContainer;