import { useTranslation } from "react-i18next";
import { useTranslation as customTranslation } from "../../../../hooks/useTranslation";
import { useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom'
import dropdownStyles from '../../../Common/Header/NavMenu/style.module.scss'
import styles from './styles.module.scss'

const MultilLangDropdown = () => {
  const { i18n } = useTranslation()
  const { t } = customTranslation()

  const location = useLocation();
  const { configs } = useSelector(store => store.configs)
  const currentLng = localStorage.getItem('i18nextLng') || 'de';

  const handleChange = (locale) => {
    i18n.changeLanguage(locale)
    window.location.reload()
  }

  return (
    <ul className={dropdownStyles.navbarNav}>
      <li className={dropdownStyles.mainMenuItem}>
        <Link to={location.pathname}>
          <img src={`/images/icons/${currentLng}.gif`} width={25} height={15} alt="Lang" />
        </Link>
        <ul className={`${dropdownStyles.subMenu} ${dropdownStyles.multiLangMenu}`}>
          {configs?.de === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('de')}>
                <img src="/images/icons/de.gif" width={25} height={15} alt="DE" />
                {t('German')}
              </div>
            </li>
          }

          {configs?.en === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('en')}>
                <img src="/images/icons/en.gif" width={25} height={15} alt="EN" />
                {t('English')}
              </div>
            </li>
          }

          {configs?.sp === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('sp')}>
                <img src="/images/icons/sp.gif" width={25} height={15} alt="SP" />
                {t('Spanish')}
              </div>
            </li>
          }

          {configs?.it === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('it')}>
                <img src="/images/icons/it.gif" width={25} height={15} alt="IT" />
                {t('Italian')}
              </div>
            </li>
          }

          {configs?.pr === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('pr')}>
                <img src="/images/icons/pr.gif" width={25} height={15} alt="PR" />
                {t('Portuguese')}
              </div>
            </li>
          }

          {configs?.fr === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('fr')}>
                <img src="/images/icons/fr.gif" width={25} height={15} alt="FR" />
                {t('French')}
              </div>
            </li>
          }

          {configs?.gr === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('gr')}>
                <img src="/images/icons/gr.gif" width={25} height={15} alt="GR" />
                {t('Greek')}
              </div>
            </li>
          }

          {configs?.ar === 'yes' &&
            <li className={dropdownStyles.menuItem}>
              <div className={styles.multiItem} onClick={() => handleChange('ar')}>
                <img src="/images/icons/ar.gif" width={25} height={15} alt="AR" />
                {t('Arabic')}
              </div>
            </li>
          }

        </ul>
      </li>
    </ul>
  );
}

export default MultilLangDropdown;