import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import cookieService from '../../../services/cookieService';
import Approval from './Approval'
import Details from './Details'
import AboutCookies from './AboutCookies'
import Loading from '../Loading';
import Modal from '../Modal'
import { CommonBtn } from '../CSTButton';

import styles from './style.module.scss'

const CookiePopup = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('approval')
  const [loading, setLoading] = useState(false)
  const [cookieData, setCookieData] = useState({})
  const [open, setOpen] = useState(false)

  const changeTab = (v) => {
    setTab(v)
  }

  const getCookieData = async () => {
    setLoading(true)
    const res = await cookieService.getCookie();
    setCookieData(res?.result);
    setLoading(false)
  }

  const checkCookie = () => {
    const name = "consent=";
    const ca = document.cookie.split(';');
    let consent;
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1);
      if (c.indexOf(name) === 0) consent = c.substring(name.length, c.length);
    }

    if (consent) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const setCookie = (v) => {
    var d = new Date();
    d.setTime(d.getTime() + (7 * 2628000 * 7));
    var expires = d.toUTCString();
    document.cookie = "consent=" + v + "; expires=" + expires;
    setOpen(false)
  }

  useEffect(() => {
    getCookieData();
    checkCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showDetail = () => {
    setTab('details')
  }

  return (
    <Modal
      open={open}
    >
      <div className={styles.popupContainer}>
        <div className={styles.tabBar}>
          <div
            className={`${styles.tabItem} ${tab === 'approval' && styles.active}`}
            onClick={() => changeTab('approval')}
          >
            {t('Approval')}
          </div>
          <div
            className={`${styles.tabItem} ${tab === 'details' && styles.active}`}
            onClick={() => changeTab('details')}>
            {t('Details')}
          </div>
          <div
            className={`${styles.tabItem} ${tab === 'about_cookie' && styles.active}`}
            onClick={() => changeTab('about_cookie')}>
            {t('About Cookies')}
          </div>
        </div>

        <div className={styles.mainContent}>
          {loading && <Loading />}
          {tab === 'approval' && <Approval content={cookieData.approval} />}
          {tab === 'details' && <Details />}
          {tab === 'about_cookie' && <AboutCookies content={cookieData.about_cookie} />}
        </div>

        <div className={styles.footer}>
          <div className={styles.footerItem}>
            {tab !== 'details'
              ? <CommonBtn
                onClick={showDetail}
                color="white"
                size="small"
                borderRadius
                width="100%"
              >
                {t('Settings')}
              </CommonBtn>
              : <CommonBtn
                onClick={() => setCookie('yes')}
                color="primary"
                size="small"
                borderRadius
                width="100%"
              >
                {t('Allow selection')}
              </CommonBtn>
            }
          </div>
          <div className={styles.footerItem}>
            <CommonBtn
              onClick={() => setCookie('no')}
              color="primary"
              size="small"
              borderRadius
              width="100%"
            >
              {t('Reject')}
            </CommonBtn>
          </div>
          <div className={styles.footerItem}>
            <CommonBtn
              onClick={() => setCookie('yes')}
              color="primary"
              size="small"
              borderRadius
              width="100%"
            >
              {t('Accept All')}
            </CommonBtn>
          </div>

        </div>

      </div>
    </Modal>
  );
}

export default CookiePopup;