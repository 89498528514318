import styles from './style.module.scss'

export const CommonBtn = (props) => {
  const btnStyle = {
    width: props.width ? props.width : 'initial',
    borderRadius: props?.borderRadius ? '6px' : '0px',
    backgroundColor: props?.color === 'primary' ? '#5096e5' 
          : props?.color === 'success' ? '#00acb1'
          : props?.color === 'danger' ? '#e15757'
          : props?.color === 'white' ? '#fff'
          : '',
    padding: props?.size === 'small' ? '11px 15px' : '16px 23px',
    textTransform: props?.uppercase ? 'uppercase': 'initial',
    color: props?.color === 'white' ? '#005963' : 'white',
    fontSize: props?.size === 'small' ? '14px' : '16px',
  }

  return ( 
    <button 
      type={props.type || 'button'} 
      style={btnStyle} 
      className={`${styles.commonBtn} ${props.disabled ? styles.disabled : ''} ${props.className}`} 
      disabled = {props.disabled}
      aria-label={props?.ariaLabel}
      onClick={props.onClick}
    >
      {props.children}
    </button>
   );
}

export const SmallMarkBtn = (props) => {
  const btnStyle = {
    width: props.width ? props.width : 'initial',
    textTransform: props?.uppercase ? 'uppercase': 'initial',
  }

  const btnClass = props?.color === 'primary' ? `${styles.primary} ${styles.smallMarkBtn}`
                  : props?.color === 'success' ? `${styles.success} ${styles.smallMarkBtn}`
                  : props?.color === 'danger' ? `${styles.danger} ${styles.smallMarkBtn}`
                  : props?.color === 'white' ? `${styles.white} ${styles.smallMarkBtn}`
                  : '';

  return ( 
    <button 
      type={props.type || 'button'} 
      style={btnStyle} 
      className={`${btnClass} ${props.disabled ? styles.disabled : ''} ${props.className}`} 
      disabled = {props.disabled}
      aria-label={props?.ariaLabel}
      onClick={props.onClick}
    >
      {props.children}
    </button>
   );
}

export const OutLineBtn = (props) => {
  const btnStyle = {
    width: props.width ? props.width : 'initial',
    borderRadius: props?.borderRadius ? '6px' : '0px',
    padding: props?.size === 'small' ? '9px 15px' : '13px 23px',
    fontSize: props?.size === 'small' ? '14px' : '16px',
    textTransform: props?.uppercase ? 'uppercase': 'initial',
  }

  const btnClass = props?.color === 'primary' ? `${styles.primary} ${styles.outLineBtn}`
                  : props?.color === 'success' ? `${styles.success} ${styles.outLineBtn}`
                  : props?.color === 'danger' ? `${styles.danger} ${styles.outLineBtn}`
                  : '';

  return ( 
    <button 
      type={props.type || 'button'} 
      style={btnStyle} 
      className={`${btnClass} ${props.disabled ? styles.disabled : ''} ${props.className}`} 
      disabled = {props.disabled}
      aria-label={props?.ariaLabel}
      onClick={props.onClick}
    >
      {props.children}
    </button>
   );
}
