import React, { useState } from 'react';
import { useTranslation } from "../../../hooks/useTranslation";
import { useForm } from "react-hook-form";
import PageBackGround from '../../Common/PageBackGround';
import { CommonBtn } from "../../Common/CSTButton";
import Loading from '../../Common/Loading';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import authService from '../../../services/authService';

const PageContainer = () => {
  const { t } = useTranslation()
  const { register, getValues, handleSubmit, formState: { errors } } = useForm({});
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const onSubmit = async (data) => {
    setLoading(true)
    const res = await authService.passwordForgot(data);
    setResponse(res);
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <>
        <PageBackGround>
          <h1>{t('Forgot Password?')}</h1>
        </PageBackGround>
        <div className="container">
          <div className="row">
            <form className="offset-lg-3 col-lg-6" onSubmit={handleSubmit(onSubmit)}>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  <AiOutlineCloseCircle className="me-2" size="20" />
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  <AiOutlineCheckCircle className="me-2" size="20" />
                  {t('We-information').replace("<email>", getValues('email'))}
                </div>

              }

              <div className="mb-3">
                <label htmlFor="email" className="ps-0 mb-1">{t('E-Mail-Addresse')}</label>
                <input className="form-control" type="email" placeholder={t('E-Mail-Addresse')} autoComplete="off"
                  {...register("email", { required: `${t('Field is required')}` })}
                />
                <div className="text-danger">{errors?.email?.message}</div>
              </div>
              <div className="text-center">
                <input className="form-control" type="hidden" value="patient" {...register("role")} />
                <CommonBtn color="success" type="submit" uppercase borderRadius
                  ariaLabel={t('Forgot Password?')}
                >
                  {t('Send password')}
                </CommonBtn>
              </div>
            </form>
          </div>
        </div>
      </>
  )
}

export default PageContainer;


