import { useTranslation } from "../../../hooks/useTranslation";
import PageBackGround from '../../Common/PageBackGround';
import LoginWraper from './LoginWraper'
import RegisterWraper from './RegisterWraper'

const PageContainer = () => {
  const {t} = useTranslation()
  return (
    <>
      <PageBackGround>
        <h1>{`${t('Login')} / ${t('Registration')}`}</h1>
      </PageBackGround>
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-6 col-md-6">
            <LoginWraper />
          </div>
          <div className="col-lg-6 col-md-6">
            <RegisterWraper/>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageContainer;


