import { useTranslation } from "../../../hooks/useTranslation";
import { Link } from "react-router-dom";

import styles from './style.module.scss'

const AreYouDoctor = () => {
  const {t} = useTranslation();
  return (  
    <>
      <h5>{t('Are you a doctor?')}</h5>
      <div className={styles.registerDes}>
        {t('Increase-portal').replace('<domain>', window.location.hostname)}
      </div>
      <div className="text-end mb-3">
        <Link to="/doctor">
          {t('Find out more now')}
        </Link>
      </div>
    </>
  );
}
 
export default AreYouDoctor;