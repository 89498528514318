import { useTranslation } from "../../../../hooks/useTranslation";
import { menuList } from "../../../../utilities/constants";
import { Link, useLocation } from "react-router-dom";

import styles from './style.module.scss'

const NavMenu = () => {
  const {t}=useTranslation();
  const location = useLocation();

  return (
    <ul className={`${styles.navbarNav} ${styles.navMenu}`}>
      {menuList.map((item, index) => {
        return (
          <li className={styles.mainMenuItem} key={index}>
            {
              item.url 
                ? <Link to={item.url}>{t(item.title)}</Link>
                : <Link to={location.pathname}>{t(item.title)}</Link>
            }
            <ul className={styles.subMenu}>
              {item?.submenu?.length > 0 && item.submenu.map((menu, ind) => (
                <li className={styles.menuItem} key={ind}>
                  <Link to={menu.url}>
                    {t(menu.title)}
                  </Link>
                </li>
              )
              )}
            </ul>
          </li>
        )
      })
      }
    </ul>
  );
}

export default NavMenu;