import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from '../../../../hooks/useTranslation';
import { useSelector } from 'react-redux';
import { CommonBtn } from '../../../Common/CSTButton';
import Loading from '../../../Common/Loading';
import authService from '../../../../services/authService';
import StModal from './StModal';
import PpButton from './PpButton';

import styles from './style.module.scss'

const PageContainer = (props) => {
  const { doctor } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { configs } = useSelector(store => store.configs)
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [stripeModalOpen, setStripeModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const priceFormat = (price) => {
    price = price.toFixed(2).replace(".", ",").replace(",00", ",-");
    return price
  }

  const vatPrice = doctor?.doctorType?.price * configs?.vat / 100;
  const totalPrice = vatPrice + doctor?.doctorType?.price;
  const stripe = loadStripe(configs?.stripe_pub);

  const stripeClose = () => {
    setStripeModalOpen(false)
  }

  const changePaymentMethod = (v) => {
    setPaymentMethod(v)
  }

  const handlePay = async () => {
    setLoading(true)

    if (paymentMethod === 'bank') {
      const data = doctor?.user
      data.status = 3
      data.transaction = {
        status: 0,
        product: doctor?.doctorType?.name,
        payment_method: 'bank',
        price: totalPrice.toFixed(2)
      }
      const res = await authService.userRegister(data)
      if (res.success && res.result) {
        navigate("/doctor/register/result", { state: { ...doctor, res: res } });
      } else {
        setResponse(res)
      }
    }

    if (paymentMethod === 'stripe') {
      setStripeModalOpen(true)
    }

    if (paymentMethod === 'payrexx') {
      console.log(configs)
    }
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <Elements stripe={stripe}>
        <div className={styles.pageContainer}>
          <h5>{t('Select Payment Method')}</h5>
          <div className='row'>
            {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}
            <div className='col-lg-8'>
              <div className='border mb-2 rounded'>
                {configs?.bank_act === 'true' &&
                  <div onClick={() => changePaymentMethod('bank')} className={styles.paymentItem}>
                    <input type="radio" name="paymentMethod" checked={paymentMethod === 'bank'} onChange={() => { }} required />
                    <label>
                      <img src='/images/icons/bank.png' alt="bank" />
                      {t('Bank Transfer')}
                    </label>
                  </div>
                }
                {configs?.stripe_act === 'true' &&
                  <div onClick={() => changePaymentMethod('stripe')} className={styles.paymentItem}>
                    <input type="radio" name="paymentMethod" checked={paymentMethod === 'stripe'} onChange={() => { }} />
                    <label>
                      <img src='/images/icons/stripe.png' alt="bank" />
                      {t('Stripe')}
                    </label>
                  </div>
                }
                {configs?.paypal_act === 'true' &&
                  <div onClick={() => changePaymentMethod('paypal')} className={styles.paymentItem}>
                    <input type="radio" name="paymentMethod" checked={paymentMethod === 'paypal'} onChange={() => { }} />
                    <label>
                      <img src='/images/icons/paypal.png' alt="bank" />
                      {t('Paypal')}
                    </label>
                  </div>
                }
                {configs?.payrexx_act === 'true' &&
                  <div onClick={() => changePaymentMethod('payrexx')} className={styles.paymentItem}>
                    <input type="radio" name="paymentMethod" checked={paymentMethod === 'payrexx'} onChange={() => { }} />
                    <label>
                      <img src='/images/icons/payrexx.png' alt="bank" />
                      {t('Payrexx')}
                    </label>
                  </div>
                }
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='border mb-2 rounded'>
                <div className={styles.rightItem}>
                  <div className='mb-2'><b>{t(doctor?.doctorType?.name)}</b></div>
                  <div>{t(doctor?.user?.email)}</div>
                </div>
                <div className={styles.rightItem}>
                  <div className={styles.priceItem}>
                    <div>{t('Price')}</div>
                    <div>{priceFormat(doctor?.doctorType?.price) + ' ' + configs?.currency_symbol}</div>
                  </div>
                  <div className={styles.priceItem}>
                    <div>{t('VAT') + ' ' + configs?.vat + '%'}</div>
                    <div>{priceFormat(vatPrice) + ' ' + configs?.currency_symbol}</div>
                  </div>
                </div>
                <div className={styles.rightItem}>
                  <div className={`mb-3 ${styles.priceItem}`}>
                    <div>{t('Total Price')}</div>
                    <div>{priceFormat(totalPrice) + ' ' + configs?.currency_symbol}</div>
                  </div>
                  <div className='mb-2'>
                    {paymentMethod === 'paypal'
                      ? <PpButton
                        configs={configs}
                        doctor={doctor}
                        totalPrice={totalPrice.toFixed(2)}
                        setLoading={setLoading}
                        setResponse={setResponse}
                      />
                      :
                      <CommonBtn
                        width="100%"
                        borderRadius
                        size="small"
                        color="primary"
                        disabled={!paymentMethod}
                        onClick={handlePay}
                      >
                        {t('Process Payment')}
                      </CommonBtn>
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {stripeModalOpen &&
          <StModal
            stripeClose={stripeClose}
            doctor={doctor}
            totalPrice={totalPrice.toFixed(2)}
            configs={configs}
          />
        }

      </Elements>
  )
}

export default PageContainer;