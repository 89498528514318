import { HelmetTags } from '../../components/Common/HelmetTags'
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/Page/PageContainer'

const Home = () => {
  const { page } = useParams();
  return (
    <>
      <HelmetTags
        title={page}
      />
      <PageContainer/>
    </>
  )
}

export default Home;

