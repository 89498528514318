import { useLocation } from 'react-router-dom'
import { HelmetTags } from '../../../../components/Common/HelmetTags'
import PageContainer from '../../../../components/Doctor/Register/Result/PageContainer'

const Result = () => {
  const { state } = useLocation()

  return (
    <>
      <HelmetTags
        title="Doctor Reigstration"
      />
      <PageContainer doctor={state}/>
    </>
  )
}

export default Result;

