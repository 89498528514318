import axios from 'axios'
import { backendUrl } from '../utilities/constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getFeedbacks = async () => {
  try {
    const response = await axios.get(backendUrl + '/api/get_feedbacks/'+currentLng);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const feedbackService = {
  getFeedbacks,
}

export default feedbackService