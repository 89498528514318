import helmetData from '../../utilities/helmetdata.json'
import { Helmet } from 'react-helmet'

export const HelmetTags = (props) => {
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width,initial-scale=1"></meta>
      <title>{props.title || helmetData.app.title}</title>
      <meta name='description' content={props.description || helmetData.app.description} />
      <meta name='keywords' content={props.keywords || helmetData.app.keywords} />
      {props.robots ? (
        <meta name='robots' content={props.robots} />
      ) : (
        helmetData.app.robots && <meta name='robots' content={helmetData.app.robots} />
      )}
      <link rel='canonical' href={props.canonicalUrl || helmetData.app.canonicalUrl} />
    </Helmet>
  )
}
