import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "../../../hooks/useTranslation";
import { MdOutlineCategory } from "react-icons/md";
import SelectSearch from "react-select-search";
import { useSelector } from 'react-redux';
import expertiseService from '../../../services/expertiseService';
import 'react-select-search/style.css'

const NameSearch = (props) => {
  const {searchRequest, setSearchRequest} = props;
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [options, setOptions] = useState([
    { name: t('No selection'), value: ''},
    {
      type: 'group',
      name: t('Expertise'),
      items: []
    },
    {
      type: 'group',
      name: t('Name'),
      items: []
    },
  ])

  const doctors = useSelector(store => store.doctors.items)

  const getOptions = async () => {
    const res = await expertiseService.getExpertises();
    const newData = [...options]
    const nameArr = []
    doctors.forEach(item => {
      nameArr.push({name: item.first_name + ' ' + item.last_name, value: item.first_name + ' ' + item.last_name})
    })

    const expertiseArr = []
    res?.result.forEach(item => {
      expertiseArr.push({name: item.name,  value: item.name})
    })

    newData.forEach((item, ind) => {
      if(item.name === t('Name')){
        newData[ind].items = nameArr
      }
      if(item.name === t('Expertise')){
        newData[ind].items = expertiseArr
      }
    })

    setOptions(newData)
  }

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return options;
      }
      const updatedItems = items.map((list) => {
        const newItems = list?.items?.filter((item) => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        return { ...list, items: newItems };
      });
      return updatedItems;
    };
  };

  const handleChange = (...args) => {
    if(args[1].groupName === t('Expertise')){
      setSearchRequest({
        ...searchRequest,
        nameCt: 'expertise',
        nameValue: args[1].value
      })
    }
    if(args[1].groupName === t('Name')){
      const newData = {
        ...searchRequest,
        nameCt: 'name',
        nameValue: args[1].value
      }
      navigate("/doctor-list", {state: newData});
    }
  };


  return (
    <div className="flex-grow-1 d-flex position-relative h-100">
      <MdOutlineCategory size="18" className="inIcon leftIcon" />
      <SelectSearch
        name="name"
        options={options}
        filterOptions={handleFilter}
        value={searchRequest.nameValue}
        search
        placeholder={t('Expertise') + ', ' + t('Name')}
        onChange={handleChange}
      />
    </div>
  );
}

export default NameSearch;