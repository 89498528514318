import React, { useState } from 'react';
import Aside from '../Aside'
import { FiMenu } from "react-icons/fi";
import styles from './style.module.scss'

const HeaderActionMenu = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleShow = () => {
    setIsSidebarOpen(true)
  }
  const handleClose = () => {
    setIsSidebarOpen(false)
  }

  return ( 
    <>
      <div className={styles.menuIconContainer} onClick={handleShow}>
        <FiMenu color="black" size="20"/>
      </div>
      <Aside handleClose={handleClose} isSidebarOpen={isSidebarOpen}/>
    </>
  );
}

export default HeaderActionMenu;