import { useSelector, useDispatch } from 'react-redux/es/exports';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt, FaEye, FaRegStar, FaRegBell, FaUserTie, FaSignOutAlt } from "react-icons/fa";
import { useTranslation } from '../../../hooks/useTranslation';
import { userLogout } from '../../../store/auth'
import { backendUrl } from '../../../utilities/constants'
import styles from './style.module.scss'

const Sidebar = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {auth} = useSelector(store => store.auth)

  const signOut = () => {
    dispatch(userLogout(auth?.token))
  }

  return (
    <div className={styles.sideBarContainer}>
      <div className={styles.topContainer}>
        <img 
          src={auth?.photo ? backendUrl+'/images/profile/'+auth?.photo+'?v='+new Date().getTime() : '/images/img/user_color.png'}
          alt='User Profil'
          className='mb-2'
        />
        <div>
          {auth?.email}
        </div>
      </div>
      <div className={styles.sidebarMain}>
        <Link to='./dates'>
          <FaRegCalendarAlt size={20}/>
          {t('My dates')}
        </Link>
        <Link to='./observed'>
          <FaEye size={20}/>
          {t('Observed practitioners')}
        </Link>
        <Link to='./reviews'>
          <FaRegStar size={20}/>
          {t('My reviews')}
        </Link>
        <Link to='./news'>
          <FaRegBell size={20}/>
          {t('News')}
        </Link>
        <Link to='./account'>
          <FaUserTie size={20}/>
          {t('My account')}
        </Link>
        <Link to='/' onClick={signOut}>
          <FaSignOutAlt size={20}/>
          {t('Log out')}
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;