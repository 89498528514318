import { Routes, Route } from "react-router-dom";
import Sidebar from "../../components/Patient/Layout/Sidebar";
import Home from './Home'
import Dates from './Dates'
import Observed from './Observed'
import Reviews from './Reviews'
import News from './News'
import Account from './Account'

const Patient = () => {
  return (
    <div className="container userMain">
      <div className="row noMargin border">
        <div className="col-lg-3 col-md-4">
          <Sidebar />
        </div>
        <div className="col-lg-9 col-md-8">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dates" element={<Dates />} />
            <Route path="/observed" element={<Observed />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/news" element={<News />} />
            <Route path="/account" element={<Account />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Patient;

