import React, {useState} from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import Switch from "react-switch";

const Details = () => {
  const { t } = useTranslation()
  const [preference, setPreference] = useState(false)
  const [statistics, setStatistics] = useState(false)
  const [marketing, setMarketing] = useState(false)

  return (
    <div>
      <div className="mb-3 pb-3 border-bottom">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <b>{t('Necessary')}</b>
          <Switch
            onChange={() => {}}
            disabled={true}
            checked={true}
            width={50}
            height={20}
          />
        </div>
        <div>
          {t('Necessary-cookies')}
        </div>
      </div>

      <div className="mb-3 pb-3 border-bottom">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <b>{t('Preference')}</b>
          <Switch
            onChange={()=>setPreference(prev => !prev)}
            checked={preference}
            width={50}
            height={20}
          />
        </div>
        <div>
          {t('Preference-in')}
        </div>
      </div>

      <div className="mb-3 pb-3 border-bottom">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <b>{t('Statistics')}</b>
          <Switch
            onChange={()=>setStatistics(prev => !prev)}
            checked={statistics}
            width={50}
            height={20}
          />
        </div>
        <div>
          {t('Statistics-anonymously')}
        </div>
      </div>

      <div className="mb-3 pb-3 border-bottom">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <b>{t('Marketing')}</b>
          <Switch
            onChange={()=>setMarketing(prev => !prev)}
            checked={marketing}
            width={50}
            height={20}
          />
        </div>
        <div>
          {t('Marketing-advertisers')}
        </div>
      </div>

    </div>
  );
}

export default Details;