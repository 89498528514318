import { useLocation } from "react-router-dom";
import HeaderTop from "./HeaderTop";
import LogoContainer from "./LogoContainer";
import NavMenu from "./NavMenu";
import HeaderActions from "./HeaderActions";

import styles from './style.module.scss';

const Header = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className={styles.headerWraper}>
      <div className={
        path !== '/' && !path.includes('/patient') && path !== '/doctor' && !path.includes('/doctor/')
          ? `container ${styles.container}`
          : ``
      }>
        <div className={styles.headerTopWraper}>
          <HeaderTop />
        </div>
        <div className={styles.headerNavWraper}>
          <LogoContainer />
          <NavMenu />
          <HeaderActions />
        </div>
      </div>
    </div>
  );
}

export default Header;