import { Link } from 'react-router-dom'
import { useTranslation } from '../../../hooks/useTranslation';

import styles from './style.module.scss'

const Mydates = () => {
  const {t} = useTranslation()
  return (  
    <div className={styles.MydatesContainer}>
      <div className='d-flex align-items-center justify-content-between'>
        <h2>{t('My dates')}</h2>
        <Link to='/'>{t('Make an appointment')}</Link>
      </div>
      <div className={styles.itemRow}>
        <img src='./images/img/appointment-vector.webp' alt="Appointment"/>
        <div>
          {t('Book-here')}
        </div>
      </div>
    </div>
  );
}
 
export default Mydates;