import React from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';

const PageContainer = (props) => {
  const { doctor } = props
  const { t } = useTranslation()
  console.log(doctor)

  return (
    <>
      <br />
      {doctor.res?.success && doctor.res?.result && <div className='alert alert-success' role='alert'>{t('Please-functions')}</div>}
    </>
  )
}

export default PageContainer;