import axios from 'axios'
import { backendUrl } from '../utilities/constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const userLogin = async (userData) => {
  try {
    const response = await axios.post(backendUrl + '/api/login/' + currentLng, userData);
    const auth = response.data.result;
    auth.token = response.data.token;
    localStorage.setItem('auth', JSON.stringify(auth));
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const userLogout = async (userToken) => {
  try {
    const response = await axios.post(backendUrl + '/api/logout', userToken);
    localStorage.removeItem('auth');
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const userRegister = async (userData) => {
  try {
    const response = await axios.post(backendUrl + '/api/register/' + currentLng, userData);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const activeEmail = async (time, id) => {
  try {
    const response = await axios.get(backendUrl + '/api/active_email/' + time + '/' + id);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const passwordForgot = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/password_forgot/' + currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const checkLoggedin = async (token) => {
  try {
    const response = await axios.get(backendUrl + '/api/check_loggedin', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const userUpdate = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/user_update/'+currentLng, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const changePassword = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/change_password', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const deleteUser = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/delete_user', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const checkEmail = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/check_email', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const authService = {
  userRegister,
  userLogin,
  userLogout,
  activeEmail,
  passwordForgot,
  checkLoggedin,
  userUpdate,
  changePassword,
  deleteUser,
  checkEmail
}

export default authService