import SliderContainer from './SliderContainer'
import HomeSearch from './HomeSearch';
import Service from './Service';
import WhyWe from './WhyWe';
import OurServices from './OurServices';
import WorkProcess from '../Common/WorkProcess';
import OurTeam from './OurTeam';
import Blogs from './Blogs';
import ClientSays from './ClientSays';

const PageContainer = () => {

  return (
    <>
      <SliderContainer/>
      <HomeSearch/>
      <Service/>
      <WhyWe/>
      <OurServices/>
      <WorkProcess/>
      <OurTeam/>
      <Blogs/>
      <ClientSays/>
    </>
  )
}

export default PageContainer;


