import { useTranslation } from '../../../hooks/useTranslation';
import { BsInfoCircle } from "react-icons/bs";
import styles from './style.module.scss'

const PageContainer = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.reviewsContainer}>
      <h1>{t('My submitted ratings')}</h1>
      <div className='font-14'>{t('Here-here')}</div>
      <div className='blue-info'>
        <BsInfoCircle />
        {t('You have not yet submitted a rating yourself.')}
      </div>
    </div>
  );
}

export default PageContainer;