import { useTranslation } from "../../../../hooks/useTranslation";
import { useSelector } from 'react-redux';
import { BiMap } from "react-icons/bi";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

import styles from './style.module.scss'

const Contact = () => {
  const { t } = useTranslation()
  const admin = useSelector(store => store.admin.admin)

  return (
    <div className={styles.contactWrap}>
      <div className={styles.contactContainer}>
        
        <div className={styles.contactItem}>
          <div className={styles.iconWrap}>
            <BiMap size="26" />
          </div>
          <div>
            <div className={styles.contactTitle}>
              {t('Our address')}
            </div>
            <div className={styles.contactValue}>
              {`${admin?.state}, ${admin?.city}, ${admin?.zip}`}
            </div>
          </div>
        </div>

        <div className={styles.contactItem}>
          <div className={styles.iconWrap}>
            <AiOutlinePhone size="26" />
          </div>
          <div>
            <div className={styles.contactTitle}>
              {t('Give us a call')}
            </div>
            <div className={styles.contactValue}>
              {admin?.phone}
            </div>
          </div>
        </div>

        <div className={styles.contactItem}>
          <div className={styles.iconWrap}>
            <AiOutlineMail size="26" />
          </div>
          <div>
            <div className={styles.contactTitle}>
              {t('Our Email')}
            </div>
            <div className={styles.contactValue}>
              {admin?.email}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Contact;