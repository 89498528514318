import { HelmetTags } from '../../../components/Common/HelmetTags'
import CheckLoggedin from '../../../components/Patient/Auth/CheckLoggedin'
import PageContainer from '../../../components/Patient/Observed/PageContainer'

const Dates = () => {
  return (
    <CheckLoggedin>
      <HelmetTags
        title="My Observed"
      />
      <PageContainer/>
    </CheckLoggedin>
  )
}

export default Dates;

