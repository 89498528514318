import axios from 'axios'
import { backendUrl } from '../utilities/constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getAllDoctorTypes = async () => {
  try {
    const response = await axios.get(backendUrl + '/api/get_doctor_types/'+currentLng);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const doctorTypeService = {
  getAllDoctorTypes
}

export default doctorTypeService