import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BlogItem from "../../Home/Blogs/BlogItem";
import Pagenation from '../../Common/Pagenation'
import { countPerPage } from '../../../utilities/constants';

import styles from './style.module.scss';

const BlogsContainer = () => {
  const blogs = useSelector(store => store.blogs.items)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [pageData, setPageData] = useState(null)

  useEffect(() => {
    const pages = Math.ceil(blogs.length / countPerPage.blogs)
    setTotalPages(pages)
  }, [blogs])

  const handleChangePage = (p) => {
    setCurrentPage(p)
  }

  useEffect(() => {
    const startInd = (currentPage-1)*countPerPage.blogs;
    const endInd = currentPage*countPerPage.blogs;
    const newArr = blogs.slice(startInd, endInd);
    setPageData(newArr)
  }, [currentPage, blogs])

  return (
    <div className="container">
      {
        blogs &&
        <div className={styles.blogsContainer}>
          <div className={styles.blogItemWraper}>
            <div className="row">
              { pageData &&
                pageData.map(item =>
                  <div className="col-lg-4 col-md-6 mb-3" key={item.id}>
                    <BlogItem item={item} />
                  </div>
                )
              }
            </div>
          </div>

          <div className={styles.pagenationWraper}>
            <Pagenation
              currentPage={currentPage}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
            />
          </div>

        </div>

      }
    </div>
  )
}

export default BlogsContainer;


