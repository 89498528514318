import React, { useState } from 'react';
import Modal from '../../../Common/Modal';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import { CommonBtn } from '../../../Common/CSTButton';
import { useTranslation } from '../../../../hooks/useTranslation';
import PartLoading from '../../../Common/PartLoading';
import paymentService from '../../../../services/paymentService';
import authService from '../../../../services/authService';

import styles from './style.module.scss'

const StModal = (props) => {
  const { stripeClose, doctor, totalPrice, configs } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const inputStyle = {
    fontSize: '18px',
    '::placeholder': {
      color: '#dfdfdf',
    },
  }

  const onSubmit = async () => {
    setLoading(true)
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = elements?.getElement(CardNumberElement);

    if (cardNumberElement) {
      const { error } = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardNumberElement
      });

      if (!error) {
        const data = {
          amount: totalPrice*100,
          currency: configs?.currency_name,
          sec_key: configs?.stripe_sec
        }
        const res = await paymentService.stripePay(data)

        if(!res.success){
          setError(res?.result)
        } else {
          const data = doctor?.user
          data.status = 0
          data.transaction = {
            status: 1,
            product: doctor?.doctorType?.name,
            payment_method: 'stripe',
            price: totalPrice
          }
          const response = await authService.userRegister(data)
          if (response.success && response.result) {
            navigate("/doctor/register/result", { state: { ...doctor, res: response } });
          } else {
            setError(response.result)
          }
        }
      } else {
        setError(error.message)
      }
    }
    setLoading(false)
  }

  return (
    <Modal open={true}>
      <div className={styles.stripeModalContainer}>
        {loading && <PartLoading />}
        <div className='row'>
          <div className='col-lg-12 mb-3'>
            <div>
              {t('Card Number')}
            </div>
            <div className={styles.cardInputWraper}>
              <CardNumberElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
          <div className='col-lg-8 col-md-8 col-8 mb-3'>
            <div>
              {t('Expire Date')}
            </div>
            <div className={styles.cardInputWraper}>
              <CardExpiryElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
          <div className='col-lg-4 col-md-4 col-4 mb-3'>
            <div>
              {t('CVC Code')}
            </div>
            <div className={styles.cardInputWraper}>
              <CardCvcElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
        </div>

        {error &&
          <div className='mb-2 text-danger'>{t(error)}</div>
        }

        <div className='row'>
          <div className='col-lg-6'>
            <CommonBtn
              width="100%"
              borderRadius
              size="small"
              color="primary"
              onClick={onSubmit}
            >
              {t('Process Payment')}
            </CommonBtn>
          </div>
          <div className='col-lg-6'>
            <CommonBtn
              width="100%"
              borderRadius
              size="small"
              color="white"
              onClick={stripeClose}
            >
              {t('Cancel')}
            </CommonBtn>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default StModal;