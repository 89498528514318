import { useLocation } from 'react-router-dom'
import { HelmetTags } from '../../components/Common/HelmetTags'
import PageContainer from '../../components/DoctorList/PageContainer'

const DoctorList = () => {
  const { state } = useLocation()
  return (
    <>
      <HelmetTags
        title="Doctor List"
      />
      <PageContainer searchRequest={state}/>
    </>
  )
}

export default DoctorList;

