import React, { useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { CommonBtn } from '../../Common/CSTButton'
import { userLogout } from '../../../store/auth';
import { useSelector, useDispatch } from 'react-redux';
import authService from '../../../services/authService';
import PartLoading from "../../Common/PartLoading";
import Modal from '../../Common/Modal';

import styles from './style.module.scss'

const DeleteAccount = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  const { auth } = useSelector(store => store.auth)
  const [open, setOpen] = useState(false)

  const submitData = async (data) => {
    setLoading(true)
    const res = await authService.deleteUser(data);
    if (res.success && res.result) dispatch(userLogout(auth?.token));
    setResponse(res)
    setLoading(false)
  }

  const deleteAccount = () => {
    const newData = {
      token: auth?.token,
      id: auth?.id
    }
    setOpen(false)
    submitData(newData);
  }

  return (
    <>
      <div className={styles.formWrapper}>
        {loading && <PartLoading />}
        <div className={styles.formTitle}>
          {t('Delete account')}
        </div>
        {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}
        <div className='mb-3'>
          {t('Here you can delete your access or your account.')}
        </div>
        <CommonBtn type="button" color="primary" borderRadius size="small" onClick={() => setOpen(true)}>
          {t('Delete account')}
        </CommonBtn>
      </div>

      <Modal open={open}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            {t('Delete account')}
          </div>
          <div className={styles.modalMain}>
            {t('Are you sure?')}
          </div>
          <div className={styles.modalFooter}>
            <CommonBtn type="button" color="danger" borderRadius size="small" className="me-2" onClick={deleteAccount}>
              {t('Delete account')}
            </CommonBtn>
            <CommonBtn type="button" color="success" borderRadius size="small" onClick={() => setOpen(false)}>
              {t('Cancel')}
            </CommonBtn>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteAccount;