import { useTranslation } from "../../../hooks/useTranslation"
import { useSelector } from "react-redux"
import SubScribe from '../SubScribe'
import Slider from './Slider'

import styles from './style.module.scss'

const OurServices = () => {
  const { t } = useTranslation();
  const services = useSelector(store => store.services.items)
  return (
    <div className={styles.Wraper}>
      <div className="container">
        {services?.length > 0 &&
          <>
            <div className={styles.title}>
              <div className={styles.smallTitle}>{t('What We do')}</div>
              <h3>{t('Our Services')}</h3>
            </div>
            <Slider services={services}/>
          </>
        }
        <SubScribe />
      </div>
    </div>
  )
}

export default OurServices;