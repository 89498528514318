import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { HelmetTags } from './components/Common/HelmetTags'
import Header from './components/Common/Header';
import Footer from './components/Common/Footer';
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import BlogList from './pages/BlogList'
import DoctorList from './pages/DoctorList'
import Doctor from './pages/Doctor'
import ContactUs from './pages/ContactUs'
import NotFindPage from './pages/404'
import ActiveEmail from "./pages/ActiveEmail";
import Page from "./pages/Page";
import CookiePopup from './components/Common/CookiePopup'
import Loading from './components/Common/Loading';

import { useSelector, useDispatch } from 'react-redux';
import { getTexts } from "./store/texts";
import { fetchAll as configFetchAll } from './store/configs';
import { fetchAll as adminFetchAll } from './store/adminDetail';
import { fetchAll as servicesFetchAll } from "./store/services";
import { fetchAll as blogsFetchAll } from "./store/blogs";
import { fetchAll as doctorsFetchAll } from "./store/doctors";
import { fetchAll as slidersFetchAll } from "./store/sliders";

// ======== Patient ==========================
import PatientLogin from "./pages/Patient/Auth/Login";
import PatientRegister from "./pages/Patient/Auth/Register";
import Forgot from './pages/Patient/Auth/Forgot'
import Patient from './pages/Patient'

import './App.scss';

function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTexts())
    dispatch(configFetchAll())
    dispatch(adminFetchAll())
    dispatch(servicesFetchAll())
    dispatch(blogsFetchAll())
    dispatch(doctorsFetchAll())
    dispatch(slidersFetchAll())
  }, [dispatch])

  const { loading:textLoading } = useSelector(store => store.texts)
  const { loading:configLoading } = useSelector(store => store.configs)
  const { loading:adminLoading } = useSelector(store => store.admin)
  const { loading:sliderLoading } = useSelector(store => store.sliders)
  const { loading:serviceLoading } = useSelector(store => store.services)
  const { loading:doctorLoading } = useSelector(store => store.doctors)
  const { loading:blogLoading } = useSelector(store => store.blogs)

  const { auth } = useSelector(store => store.auth)

  return (
    textLoading || configLoading || adminLoading || sliderLoading || serviceLoading || doctorLoading || blogLoading ?
      <Loading />
      :
      <>
        <HelmetTags />
        <BrowserRouter>
          <Header />

          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="*" element={<NotFindPage />} />
            <Route path="/active-email/:time/:id" element={<ActiveEmail />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/blog-list" element={<BlogList />} />
            <Route path="/doctor-list" element={<DoctorList />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/page/:page" element={<Page />} />

            {/* =========== Patient  ================================ */}
            <Route path="/forgot" element={auth?.role === 'patient' ? <Navigate to="/patient" /> : auth?.role === 'doctor' ? <Navigate to="/doctor" /> : <Forgot />} />
            <Route path="/login" element={auth?.role === 'patient' ? <Navigate to="/patient" /> : auth?.role === 'doctor' ? <Navigate to="/doctor" /> : <PatientLogin />} />
            <Route path="/register" element={auth?.role === 'patient' ? <Navigate to="/patient" /> : auth?.role === 'doctor' ? <Navigate to="/doctor" /> : <PatientRegister />} />
            <Route path="/patient/*" element={auth?.role === 'patient' ? <Patient /> : <Navigate to="/" />} />

            {/* =========== Doctor ==================================== */}
            <Route path="/doctor/*" element={<Doctor />} />

          </Routes>

          <Footer />
        </BrowserRouter>

        <CookiePopup />
      </>
  );
}

export default App;
