import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "../../../hooks/useTranslation"
import AnimatedNumber from "react-animated-number";
import { BiPlus } from "react-icons/bi";

import { counts } from "../../../utilities/dummy";

import styles from './style.module.scss'

const Counter = () => {
  const {t} = useTranslation();
  const myRef = useRef();
  const timeDuration = 5000
  const [items, setItems] = useState({});
  const [countVals, setCountVals] = useState({});

  useEffect(()=>{
    setItems(counts);
  },[])

  useEffect(()=>{
    if(Object.keys(items).length){
      const eleTop = myRef.current.offsetTop-500;
      const handleScroll = () => {
        if(eleTop<=window.scrollY){
          setCountVals(items);
        }
      };
      window.addEventListener('scroll', handleScroll);
    }
  }, [items])

  return (
        <div className={styles.counterContainer}  ref={myRef}>
          <div className='row'>

            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className={styles.counting}>
                <AnimatedNumber
                  value={countVals?.happy_customer}
                  duration={timeDuration}
                  formatValue={(n) => n.toFixed(0)}
                />
                <BiPlus/>
              </div>
              <div className={styles.countDes}>
                {t('Happy Customer')}
              </div>
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className={styles.counting}>
                <AnimatedNumber
                  value={countVals?.project_done}
                  duration={timeDuration}
                  formatValue={(n) => n.toFixed(0)}
                />
                <BiPlus/>
              </div>
              <div className={styles.countDes}>
                {t('Happy Customer')}
              </div>
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className={styles.counting}>
                <AnimatedNumber
                  value={countVals?.award_win}
                  duration={timeDuration}
                  formatValue={(n) => n.toFixed(0)}
                />
                <BiPlus/>
              </div>
              <div className={styles.countDes}>
                {t('Happy Customer')}
              </div>
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className={styles.counting}>
                <AnimatedNumber
                  value={countVals?.clients_work}
                  duration={timeDuration}
                  formatValue={(n) => n.toFixed(0)}
                />
                <BiPlus/>
              </div>
              <div className={styles.countDes}>
                {t('Happy Customer')}
              </div>
            </div>

          </div>
        </div>
  )
}

export default Counter