import React, { useEffect } from 'react';
import { useTranslation } from "../../../hooks/useTranslation";
import { useForm } from "react-hook-form";
import { CommonBtn } from "../../Common/CSTButton";
import { Link } from "react-router-dom";
import { userLogin, reset } from '../../../store/auth'
import { useSelector, useDispatch } from "react-redux";
import Loading from '../../Common/Loading';

import styles from './style.module.scss'

const LoginWraper = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, error, message } = useSelector(store => store.auth)

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    dispatch(userLogin(data));
  }

  return (
    loading
      ? <Loading />
      :
      <div className={styles.loginWraper}>
        <h5>{t('You are already registered?')}</h5>

        <form onSubmit={handleSubmit(onSubmit)}>
          {message && error && <div className='alert alert-danger' role='alert'>{t(message)}</div>}
          <div className="mb-3">
            <input className="form-control" type="email" placeholder={t('Email address')} autoComplete="off"
              {...register("email", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.email?.message}</div>
          </div>

          <div className="mb-3">
            <input className="form-control" type="password" placeholder={t('Password')} autoComplete="off"
              {...register("password", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.password?.message}</div>
          </div>

          <div className="mb-3 text-end">
            <Link to="/forgot">
              {t('Forgot Password?')}
            </Link>
          </div>
          <input className="form-control" type="hidden" value="patient" {...register("role")} />

          <div className="text-center">
            <CommonBtn color="success" type="submit" uppercase borderRadius
              ariaLabel={t('Login')}
            >
              {t('Login')}
            </CommonBtn>
          </div>
        </form>

      </div>
  );
}

export default LoginWraper;