import { HelmetTags } from '../../../components/Common/HelmetTags'
import CheckLoggedin from '../../../components/Patient/Auth/CheckLoggedin'
import PageContainer from '../../../components/Patient/News/PageContainer'

const News = () => {
  return (
    <CheckLoggedin>
      <HelmetTags
        title="My Messages"
      />
      <PageContainer/>
    </CheckLoggedin>
  )
}

export default News;