import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../../hooks/useTranslation"
import feedbackService from '../../../services/feedbackService';
import Slider from './Slider'

import styles from './style.module.scss'

const ClientSays = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  const getFeedback = async () => {
    const res = await feedbackService.getFeedbacks();
    setItems(res?.result);
  }

  useEffect(() => {
    getFeedback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {items?.length > 0 &&
        <div className={styles.clientSaysContainer}>
          <div className="container">
            <div className={styles.titleContainer}>
              <div className={styles.smallTitle}>{t('Customer reviews')}</div>
              <h3>{t('What our customers say')}</h3>
            </div>
            <div className={styles.sliderWraper}>
              <Slider items={items} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ClientSays;