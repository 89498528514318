import { HelmetTags } from '../../components/Common/HelmetTags'
import PageContainer from '../../components/404/PageContainer'

const NotFindPage = () => {
  return (
    <>
      <HelmetTags
        title="404 Page"
      />
      <PageContainer/>
    </>
  )
}

export default NotFindPage;

