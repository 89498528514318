import React, { useEffect, useState } from 'react';
import doctorTypeService from '../../../services/doctorTypeService';
import Loading from '../../Common/Loading';
import DoctorRegisterCard from './DoctorRegisterCard'

const PageContainer = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getAllDoctorTypes = async () => {
    setLoading(true)
    const res = await doctorTypeService.getAllDoctorTypes()
    setData(res.result)
    setLoading(false)
  }

  useEffect(() => {
    getAllDoctorTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    loading
      ? <Loading />
      :
      <div className="row">
        <div className='col-lg-4'>
          <DoctorRegisterCard row={data[0]}/>
        </div>
        <div className='col-lg-4'>
          <DoctorRegisterCard row={data[1]}/>
        </div>
        <div className='col-lg-4'>
          <DoctorRegisterCard row={data[2]}/>
        </div>
      </div>
  );
}

export default PageContainer;