import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from '../../../hooks/useTranslation';
import { CommonBtn } from '../../Common/CSTButton'
import { useSelector } from 'react-redux';
import authService from '../../../services/authService';
import PartLoading from "../../Common/PartLoading";

import styles from './style.module.scss'

const PasswordChange = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  const { auth } = useSelector(store => store.auth)
  const { handleSubmit, register, getValues, formState: { errors } } = useForm({
    defaultValues: {
      password: '',
      new_password: '',
      re_password: '',
    }
  })

  const submitData = async (data) => {
    setLoading(true)
    const res = await authService.changePassword(data);
    setResponse(res)
    setLoading(false)
  }

  const onSubmit = (data) => {
    const newData = {
      token: auth?.token,
      id: auth?.id,
      password: data.password,
      new_password: data.new_password
    }
    submitData(newData);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      {loading && <PartLoading />}
      <div className={styles.formTitle}>
        {t('Change Password')}
      </div>
      {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}
      {response.success && response.result && <div className='alert alert-success' role='alert'>{t('Changing is successed')}</div>}

      <div className='row mb-2'>
        <div className='col-lg-3 col-md-4 col-12'>
          <label className="text-start form-label">{t('Current Password')}: </label>
        </div>
        <div className='col-lg-6 col-md-7 col-12'>
          <input type="password" className="form-control smInput"
            {...register("password", { required: `${t('Field is required')}` })}
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-lg-3 col-md-4 col-12'>
          <label className="text-start form-label">{t('New Password')}: </label>
        </div>
        <div className='col-lg-6 col-md-7 col-12'>
          <input type="password" className="form-control smInput"
            {...register("new_password", {
              required: `${t('Field is required')}`,
              minLength: {
                value: 6,
                message: `${t('Password requires at least 6 charactors')}`
              },
            })}
          />
          <div className="text-danger">{errors?.new_password?.message}</div>
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-lg-3 col-md-4 col-12'>
          <label className="text-start form-label">{t('Confirm new password')}: </label>
        </div>
        <div className='col-lg-6 col-md-7 col-12'>
          <input type="password" className="form-control smInput"
            {...register("re_password", {
              required: `${t('Field is required')}`,
              minLength: {
                value: 6,
                message: `${t('Password requires at least 6 charactors')}`
              },
              validate: value =>
                value === getValues('new_password') || t('The-match')
            })}
          />
          <div className="text-danger">{errors?.re_password?.message}</div>
        </div>
      </div>
      <CommonBtn type="submit" color="primary" borderRadius size="small">
        {t('Change Password')}
      </CommonBtn>

    </form>

  );
}

export default PasswordChange;