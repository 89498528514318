import { useTranslation } from '../../../hooks/useTranslation';
import { useSelector } from 'react-redux';

import styles from './style.module.scss'

const Greeting = () => {
  const {t} = useTranslation()
  const { auth } = useSelector(store => store.auth)
  const hour = new Date().getHours();

  return ( 
    <div className={styles.greetingContainer}>
      { hour<12 ? t('Good morning') : hour<19 ? t('Good afternoon') : t('Good evening') }
      , {auth?.email}!
    </div>
  );
}
 
export default Greeting;