import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { BsDashCircleFill, BsPlusCircleFill } from "react-icons/bs";

const DayRow = (props) => {
  const { title, short, hours, register, addField, removeField } = props;
  const { t } = useTranslation()

  return (
    <tr>
      <td className='d-flex align-items-center justify-content-between'>
        {t(title)}
        <BsPlusCircleFill onClick={() => addField(short)} />
      </td>
      <td>
        <select className="form-control"
          {...register("open_hours["+short+"][0][from]")}
        >
          {[...Array(24).keys()].map(ind => <option key={ind} value={`${ind}:00`}>{`${ind}:00`}</option>)}
        </select>
        {hours[short]?.map((item, index) => {
          return index > 0 &&
            <select className="form-control" key={index}
              {...register("open_hours["+short+"][" + index + "][from]")}
            >
              {[...Array(24).keys()].map(ind => <option key={ind} value={`${ind}:00`}>{`${ind}:00`}</option>)}
            </select>
        })}
      </td>
      <td>
        <select className="form-control"
          {...register("open_hours["+short+"][0][to]")}
        >
          {[...Array(24).keys()].map(ind => <option key={ind} value={`${ind}:00`}>{`${ind}:00`}</option>)}
        </select>
        {hours[short]?.map((item, index) => {
          return index > 0 &&
            <select className="form-control" key={index}
              {...register("open_hours["+short+"][" + index + "][to]")}
            >
              {[...Array(24).keys()].map(ind => <option key={ind} value={`${ind}:00`}>{`${ind}:00`}</option>)}
            </select>
        })}
      </td>
      <td className='d-flex align-items-center'>
        <BsDashCircleFill onClick={() => removeField(short)}/>
      </td>
    </tr>
  );
}

export default DayRow;