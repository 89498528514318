import { useTranslation } from "../../../hooks/useTranslation"
import { IconPickerItem } from 'react-fa-icon-picker'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux';
import styles from './style.module.scss'

const Service = () => {
  const { t } = useTranslation();
  const services = useSelector(store => store.services.items)

  return (
    services?.length>0 &&
    <div className={styles.serviceWraper}>
      <div className="container">
        <div className="row">
          {
            services.slice(0, 4).map(item => (
              <div className="col-lg-3 col-md-4 col-sm-6 mb-5" key={item.id}>
                <div className={styles.serviceItem}>
                  <div className={styles.serviceIcon}>
                    <IconPickerItem icon={item.icon} size={34} />
                  </div>
                  <div className={styles.serviceBody}>
                    <h5>
                      <Link to={`/service/${item.slug}`}>
                        {item.title}
                      </Link>
                    </h5>
                    <p>
                      {item.description}
                    </p>
                  </div>

                  <div className={styles.readMore}>
                    <Link to={`/service/${item.slug}`}>
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          {t('Read More')}
                        </div>
                        <HiArrowNarrowRight />
                      </div>
                    </Link>
                  </div>

                </div>
              </div>
            ))
          }

        </div>
      </div>
    </div>
  );
}

export default Service