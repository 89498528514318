import { useTranslation } from "../../../hooks/useTranslation"
import { useSelector } from "react-redux"
import BlogsSlider from './BlogsSlider'

import styles from './style.module.scss'

const Blogs = () => {
  const { t } = useTranslation();
  const blogs = useSelector(store => store.blogs.items)

  return (
    <>
      {blogs?.length > 0 &&
        <div className={styles.blogsContainer}>
          <div className="container">
            <div className={styles.titleContainer}>
              <div className={styles.smallTitle}>{t('Latest news')}</div>
              <h3>{t('Our insights & articles')}</h3>
            </div>
            <div className={styles.teamSliderWraper}>
              <BlogsSlider blogs={blogs} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Blogs;