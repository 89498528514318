import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../../hooks/useTranslation"
import { Link } from 'react-router-dom'
import { IconPickerItem } from 'react-fa-icon-picker'
import { CommonBtn } from '../CSTButton'
import stepService from '../../../services/stepService';

import styles from './style.module.scss'

const WorkProcess = () => {
  const {t} = useTranslation();
  const [items, setItems] = useState([]);

  const getSteps = async () => {
    const res = await stepService.getSteps();
    setItems(res?.result);
  }

  useEffect(() => {
    getSteps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.WorkProcessContainer}>
      <div className="container">
        <div className="row mb-3">
          <div className="col-lg-5 mb-3">
            <div className={styles.titleWraper}>
              <div className={styles.smallTitle}>
                {t('Work Process')}
              </div>
              <h3>{t('How it Works?')}</h3>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            {t('You-email')}
          </div>
          <div className="col-lg-3 mb-3 text-lg-end">
            <Link to="/appointment">
                <CommonBtn color="success" borderRadius
                  ariaLabel={t('Make an appointment')}
                >
                  {t('Make an appointment')}
                </CommonBtn>
            </Link>
          </div>
        </div>

        <div className={`row ${styles.stepContainer}`}>
          {items.map(item =>
            <div className="col-lg-4 col-md-6 mb-4" key={item.id}>
              <div className={styles.contentWraper}>
                <div className={styles.iconWraper}>
                  <IconPickerItem icon={item.icon} />
                </div>
                <div className={styles.stepContent}>
                  <div>
                    <h5>{item.title}</h5>
                    <div className={styles.contentMain}>
                      {item.description}
                    </div>
                  </div>
                  <div className={styles.stepMarkContainer}>
                    <div className={styles.step}>
                      {`${t('Step')} ${item.id}`}
                    </div>
                    <span className={styles.stepCircle}></span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkProcess;