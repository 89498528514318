import { useTranslation } from "../../../../hooks/useTranslation"
import { Link } from "react-router-dom"
import { BiUserCircle } from 'react-icons/bi'
import { RiErrorWarningLine, RiUserFill } from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderSearch from '../HeaderSearch'
import MultilLangDropdown from '../MultilLangDropdown'
import { CommonBtn } from '../../CSTButton'
import HeaderActionMenu from '../HeaderActionMenu'
import { useSelector } from "react-redux"

import styles from './style.module.scss'

const HeaderActions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const location = useLocation();
  const { auth } = useSelector(store => store.auth)

  const goUserArea = () => {
    if(auth?.role === 'patient'){
      navigate("/patient")
    } 
    if(auth?.role === 'doctor'){
      navigate("/doctor")
    } 
  }

  return (
    <div className={styles.HeaderActionContainer}>
      {location.pathname === '/' && <HeaderSearch />}
      <MultilLangDropdown />

      {
        auth?.token
          ? 
            <div className={styles.regBtn}>
              <CommonBtn
                color="success"
                size="small"
                borderRadius
                ariaLabel='User Area'
                onClick={goUserArea}
              >
                <RiUserFill size="20" />
                <div className="ms-1 lh-1">
                  {auth?.email}
                </div>
              </CommonBtn>
            </div>
          : <>
            <div className={styles.regBtn}>
              <Link to='/doctor'>
                <CommonBtn
                  color="white"
                  size="small"
                  borderRadius
                  ariaLabel={t('Are you a doctor?')}
                >
                  <RiErrorWarningLine size="20" />
                  <div className="ms-1 lh-1">
                    {t('Are you a doctor?')}
                  </div>
                </CommonBtn>
              </Link>
            </div>

            <div className={styles.regBtn}>
              <Link to='/login'>
                <CommonBtn
                  color="success"
                  size="small"
                  borderRadius
                  ariaLabel={`${t('Login')} / ${t('Registration')}`}
                >
                  <BiUserCircle size="20" />
                  <div className="ms-1 lh-1">
                    {`${t('Login')} / ${t('Registration')}`}
                  </div>
                </CommonBtn>
              </Link>
            </div>
          </>
      }


      <HeaderActionMenu />
    </div>
  );
}

export default HeaderActions