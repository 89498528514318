import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageBackGround from '../Common/PageBackGround'
import DoctorListSide from './DoctorListSide'
import DoctorListMain from './DoctorListMain'
import Pagenation from '../Common/Pagenation'
import { countPerPage } from '../../utilities/constants';
import HomeSearch from '../Home/HomeSearch';

import styles from './style.module.scss'

const PageContainer = (props) => {
  const { searchRequest } = props
  const doctors = useSelector(store => store.doctors.items)
  const [result, setResult] = useState(doctors)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [pageData, setPageData] = useState(null)

  useEffect(() => {
    const pages = Math.ceil(result.length / countPerPage.doctors)
    setTotalPages(pages)
  }, [result])

  useEffect(() => {
    // console.log(searchRequest)
    // console.log(result)
    if (searchRequest?.nameCt === 'name') {
      const searchResult = doctors.filter(item =>
        item.first_name + ' ' + item.last_name === searchRequest.nameValue
        && parseInt(item.booking_method) === parseInt(searchRequest.booking_method)
      )
      setResult(searchResult);
    } 
    if (searchRequest?.nameCt === 'expertise') {
      let searchResult = doctors.filter(item =>
        item.expertise.includes(searchRequest.nameValue)
        && parseInt(item.booking_method) === parseInt(searchRequest.booking_method)
      )
      
      if(searchRequest?.locationCt === 'state'){
        searchResult = searchResult.filter(item => item.state.includes(searchRequest.locationValue))
      }

      if(searchRequest?.locationCt === 'city'){
        searchResult = searchResult.filter(item => item.city.includes(searchRequest.locationValue))
      }

      if(searchRequest?.locationCt === 'zip'){
        searchResult = searchResult.filter(item => item.zip.includes(searchRequest.locationValue))
      }

      if(searchRequest?.locationCt === 'street'){
        searchResult = searchResult.filter(item => item.street.includes(searchRequest.locationValue))
      }
      setResult(searchResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest])

  useEffect(() => {
    const startInd = (currentPage - 1) * countPerPage.doctors;
    const endInd = currentPage * countPerPage.doctors;
    const newArr = result.slice(startInd, endInd);
    setPageData(newArr)
  }, [currentPage, result])

  const handleChangePage = (p) => {
    setCurrentPage(p)
  }

  return (
    <>
      <PageBackGround>
        <div className={styles.searchWrapper}>
          <HomeSearch searchData={searchRequest} />
        </div>
      </PageBackGround>

      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <DoctorListSide />
          </div>
          <div className="col-lg-8">
            <DoctorListMain pageData={pageData} />
          </div>
        </div>

        {pageData?.length > 0 &&
          <div className={styles.pagenation}>
            <Pagenation
              currentPage={currentPage}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
            />
          </div>
        }
      </div>
    </>
  )
}

export default PageContainer;


