import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../hooks/useTranslation';
import { OutLineBtn } from '../../Common/CSTButton';
import { AiFillCheckCircle } from "react-icons/ai";
import { useSelector } from 'react-redux';

import styles from './style.module.scss';

const DoctorRegisterCard = (props) => {
  const { row } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { configs } = useSelector(store=> store.configs)

  const handleRegister = () => {
    navigate("./register", {state: row});
  }

  return (
    <div className={styles.cardContainer}>
      <div className={styles.title}>
        {t(row?.name)}
      </div>
      <div className={styles.des}>
        {row?.text}
      </div>
      <div className={styles.priceWrapper}>
        <span className={styles.currency}>{configs?.currency_symbol}</span>
        <span className={styles.price}>{row?.price}</span>
      </div>
      <div className={styles.per_month}>
        {t('Per month')}
      </div>

      <OutLineBtn color="primary" width="100%" borderRadius uppercase className="mb-3" onClick={handleRegister}>
        {t('Choose')}
      </OutLineBtn>

      <div className={styles.cardMain}>
        {!!row?.personal_info &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Change Personal Information')}</div>
          </div>
        }
        {!!row?.notify &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Get notified and comment on new reviews')}</div>
          </div>
        }
        {!!row?.publish_overview &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Publish performance overview in profile')}</div>
          </div>
        }
        {!!row?.google_presence &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Prominent presence on Google thanks to Google My Business')}</div>
          </div>
        }
        {!!row?.individual_profile &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Individual doctor profile with portrait photo, practice photos, detailed content')}</div>
          </div>
        }
        {!!row?.website &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Link to your practice website')}</div>
          </div>
        }
        {!!row?.google_top &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Top visibility on Google thanks to Google My Business')}</div>
          </div>
        }
        {!!row?.profile_service &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Professional profile service')}</div>
          </div>
        }
        {!!row?.online_appointment &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Online appointment scheduling')}</div>
          </div>
        }
        {!!row?.appointment_calendar &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Powerful appointment calendar')}</div>
          </div>
        }
        {!!row?.kbv &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('KBV-certified video consultation')}</div>
          </div>
        }
        {!!row?.personal_contact &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Individual support by personal contacts')}</div>
          </div>
        }
        {!!row?.portals40 &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Online presence on over 40 portals')}</div>
          </div>
        }
        {!!row?.treatments5 &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Prominent ad space for your 5 desired treatments')}</div>
          </div>
        }
        {!!row?.homepage_profile &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Profile presence on the homepage')}</div>
          </div>
        }
        {!!row?.profile_video &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('Publish practice videos on your profile')}</div>
          </div>
        }
        {!!row?.seo_edit &&
          <div className={styles.optionItem}>
            <AiFillCheckCircle />
            <div className='flex-1'>{t('SEO-optimized text creation by editors')}</div>
          </div>
        }
      </div>
    </div>
  );
}

export default DoctorRegisterCard;