import { useTranslation } from "../../../hooks/useTranslation";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import AreYouDoctor from './AreYouDoctor'

import styles from './style.module.scss'

const RegisterWraper = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.registerWraper}>
      <h5>{t('Are you a new user?')}</h5>
      <div className={styles.registerDes}>
        <div className="d-flex align-items-center">
          <AiOutlineCheckCircle size='20'/>
          {t('Give feedback on doctors')}
        </div>
        <div className="d-flex">
          <AiOutlineCheckCircle size='20'/>
          {t('Book-online')}
        </div>
        <div className="d-flex">
          <AiOutlineCheckCircle size='20'/>
          {t('Doctors-observe')}
        </div>
      </div>
      <div className="text-end mb-3">
        <Link to="/register">
          {t('Register')}
        </Link>
      </div>

      <AreYouDoctor/>
    </div>
  );
}

export default RegisterWraper;