import React, { useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { Controller } from "react-hook-form";
import Select from 'react-select'
import moment from 'moment-timezone';
import DayRow from './DayRow';

import styles from './style.module.scss'

const OpentHours = (props) => {
  const { register, setValue, getValues, errors, control } = props;
  const { t } = useTranslation()
  const [hours, setHours] = useState(getValues('open_hours'))
  const timezoneList = moment.tz.names();
  const options = [];
  timezoneList.forEach(item => {
    options.push({ value: item, label: item })
  })

  const addField = (day) => {
    const data = getValues('open_hours')
    data[day].push({ from: '', to: '' });
    setValue('open_hours', data);
    setHours(data)
  }

  const removeField = (day) => {
    const data = getValues('open_hours')
    if (data[day]?.length > 1) data[day].pop();
    setValue('open_hours', data);
    setHours(data)
  }

  const dayData = [
    { d: 'Monday', k: 'mon' },
    { d: 'Tuesday', k: 'tue' },
    { d: 'Wednesday', k: 'wed' },
    { d: 'Thursday', k: 'thu' },
    { d: 'Friday', k: 'fri' },
    { d: 'Saturday', k: 'sat' },
    { d: 'Sunday', k: 'sun' }
  ]

  return (
    <table className={`w-100 table table-bordered ${styles.openHourTable}`}>
      <tbody>
        <tr>
          <td></td>
          <td>{t('From')}</td>
          <td>{t('To')}</td>
          <td></td>
        </tr>
        {dayData.map(item =>
          <DayRow
            key={item.k}
            title={item.d}
            short={item.k}
            hours={hours}
            register={register}
            addField={addField}
            removeField={removeField}
          />
        )}
        <tr>
          <td>{t('Timezone')}</td>
          <td colSpan={3}>
            <Controller
              name='timezone'
              control={control}
              render={({
                field: { value, onChange }
              }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  options={options}
                  className={styles.selectContainer}
                />
              )}
              rules={{ required: t('Field is required') }}
            />
            <div className="text-danger">{errors?.timezone?.message}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default OpentHours;