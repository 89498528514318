import React, { useState } from 'react';
import { Autoplay } from "swiper";
import { backendUrl } from '../../../utilities/constants';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './style.module.scss'

const Slider = (props) => {
  const {items} = props
  const [swiperInstance, setSwiperInstance] = useState(null)

  return (
    <div className={styles.sliderWraper}
      onMouseEnter={() => swiperInstance.autoplay.pause()}
      onMouseLeave={() => swiperInstance.autoplay.run()}
    >
      <Swiper
        onInit={(swiper) => setSwiperInstance(swiper)}
        autoplay={{
          delay: 2500,
        }}
        centeredSlides={items?.length === 1 ? true : false}
        loop={items?.length > 3 ? true : false}
        modules={[Autoplay]}
        spaceBetween={15}
        breakpoints={{
          628: {
            slidesPerView: 2,
          },
          920: {
            slidesPerView: 3,
          },
        }}
      >
        {items.map(item => (
          <SwiperSlide key={item.id} style={{ height: 'initial' }}>
            <div className={styles.slideContainer}>
              <img src={backendUrl + '/images/feedback/' + item.image + '?v=' + new Date().getTime()} alt={item.client} className={styles.imageWraper} />
              <div className={styles.contentWraper}>
                <div className={styles.content}>
                  {`"${item.text}"`}
                </div>
                <div className={styles.nameExpertise}>
                  <div className={styles.name}>
                    {item.client}
                  </div>
                  <div className={styles.expertise}>
                    {item.expertise}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))
        }
      </Swiper>
    </div>
  )
}

export default Slider;