import { useTranslation } from '../../../hooks/useTranslation';
import { BsInfoCircle } from "react-icons/bs";
import styles from './style.module.scss'

const PageContainer = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.observedContainer}>
      <h1>{t('My observed entries')}</h1>
      <div className='font-14'>{t('You-date')}</div>
      <div className='blue-info'>
        <BsInfoCircle />
        {t('You are currently not monitoring any entry.')}
      </div>
    </div>
  );
}

export default PageContainer;