import { useTranslation } from "../../hooks/useTranslation";
import PageBackGround from '../Common/PageBackGround';
import BlogsContainer from './BlogsContainer'

const PageContainer = () => {
  const {t} = useTranslation()
  return (
    <>
      <PageBackGround>
        <h1>{t('Blog List')}</h1>
      </PageBackGround>
      <BlogsContainer/>
    </>
  )
}

export default PageContainer;


