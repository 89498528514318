import { HelmetTags } from '../../components/Common/HelmetTags'
import PageContainer from '../../components/Home/PageContainer'
import { useSelector } from 'react-redux'

const Home = () => {
  const configs = useSelector(store => store.configs.configs)
  
  return (
    <>
      <HelmetTags
        title={configs?.site_title ? configs?.site_title : ''}
      />
      <PageContainer/>
    </>
  )
}

export default Home;

