import { useTranslation } from "../../../hooks/useTranslation";
import { CommonBtn } from "../../Common/CSTButton"
import { BsSearch } from "react-icons/bs";
import { GoLocation } from "react-icons/go";

const DoctorSearch = () => {
  const { t } = useTranslation()

  const handleSubmit = () => {
    console.log('adsf');
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="input-group">
          <div className="d-flex position-relative">
            <GoLocation size="18" className="inIcon leftIcon" />
            <input className="form-control prevIcon" type='text' id='location' name='location' placeholder={t('Location')} />
          </div>
          <CommonBtn color="primary" size="small" type="submit" uppercase
            ariaLabel={t('Find Now')}
          >
            <BsSearch size="16" />
            <div className="ms-1">
              {t('Find Now')}
            </div>
          </CommonBtn>
        </div>
      </div>
    </form>
  );
}

export default DoctorSearch;