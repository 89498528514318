import { useTranslation } from '../../../hooks/useTranslation';
import { BsInfoCircle } from "react-icons/bs";
import styles from './style.module.scss'

const PastAppointments = () => {
  const {t} = useTranslation()
  return (  
    <div className={styles.appointmentRow}>
      <h2>{t('Past appointments')}</h2>
      <div className={styles.rowDes}>{t('You-him')}</div>
      <div className='blue-info'>
        <BsInfoCircle/>
        {t('There are currently no past events.')}
      </div>
    </div>
  );
}
 
export default PastAppointments;