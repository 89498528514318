import { useTranslation } from "../../../../hooks/useTranslation";
import { Link } from 'react-router-dom'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaGoogle } from "react-icons/fa";
import { CommonBtn } from "../../CSTButton";
import { usefullMenus } from "../../../../utilities/constants";
import { useSelector } from 'react-redux';
import { backendUrl } from "../../../../utilities/constants";

import styles from './style.module.scss'

const Menus = () => {
  const { t } = useTranslation()
  const services = useSelector(store => store.services.items)

  const handleSubmit = (e) => {
    console.log('submitted')
    e.preventDefault();
  }

  return (
    <div className={styles.menusWrap}>
      <div className="row">
        <div className="col-lg-4 mb-3">
          <div className={styles.logoSocial}>
            <div>
              <img src={backendUrl + '/images/site_logo.png?v=' + new Date().getTime()}
                className={styles.footerLogo} alt="footer-logo"
              />
              <div className={styles.footerShortDes}>
                It is a long established fact that a reader will be distracted by the readable.
              </div>
            </div>
            <div className={styles.social}>
              <FacebookShareButton url={window.origin}>
                <div className={styles.socialBtn}>
                  <FaFacebookF />
                </div>
              </FacebookShareButton>
              <TwitterShareButton url={window.origin}>
                <div className={styles.socialBtn}>
                  <FaTwitter />
                </div>
              </TwitterShareButton>
              <a href={`https://instagram.com?url=${window.origin}`} target="_blank" rel="noreferrer" title="Instagram">
                <div className={styles.socialBtn}>
                  <FaInstagram />
                </div>
              </a>
              <LinkedinShareButton url={window.origin}>
                <div className={styles.socialBtn}>
                  <FaLinkedin />
                </div>
              </LinkedinShareButton>
              <a href={`https://google.com?q=${window.origin}`} target="_blank" rel="noreferrer" title="Google">
                <div className={styles.socialBtn}>
                  <FaGoogle />
                </div>
              </a>
            </div>
          </div>
        </div>

        {services?.length > 0 &&
          <div className="col-lg-3 col-sm-3 mb-3">
            <div className={styles.menuTitle}>
              {t('Services')}
            </div>
            <div className={styles.menuContent}>
              {services.map(item =>
                <Link to={`/services/${item.slug}`} key={item.id}>
                  {item.title}
                </Link>
              )}
            </div>
          </div>
        }

        <div className="col-lg-2 col-sm-3 mb-3">
          <div className={styles.menuTitle}>
            {t('Useful Links')}
          </div>
          <div className={styles.menuContent}>
            {usefullMenus.map(item =>
              <Link to={`/${item.url}`} key={item.title}>
                {t(item.title)}
              </Link>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 mb-3">
          <div className={styles.menuTitle}>
            {t('Subscribe')}
          </div>
          <form onSubmit={handleSubmit}>
            <input className="form-control" type="text" name="email" placeholder="Email" />
            <div className="mb-3"></div>
            <CommonBtn
              type="submit"
              borderRadius
              uppercase
              color="success"
              width="100%"
              ariaLabel={t('Subscribe')}
            >
              {t('Subscribe')}
            </CommonBtn>
          </form>
          <div className="mt-3">
            {t('Get-unsubscribe')}
          </div>
        </div>
      </div>
    </div >
  );
}

export default Menus;