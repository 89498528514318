import { useTranslation } from '../../../hooks/useTranslation';
import { BsInfoCircle } from "react-icons/bs";
import styles from './style.module.scss'

const CurrentAppointments = () => {
  const {t} = useTranslation()
  return (  
    <div className={styles.appointmentRow}>
      <h2>{t('Upcoming appointments')}</h2>
      <div className={styles.rowDes}>{t('You-time')}</div>
      <div className='blue-info'>
        <BsInfoCircle/>
        {t('You currently have no open appointment bookings.')}
      </div>
    </div>
  );
}
 
export default CurrentAppointments;