import axios from 'axios'
import { backendUrl } from '../utilities/constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getPage = async (page) => {
  try {
    const response = await axios.get(backendUrl + '/api/get_page/' + page + '/' + currentLng);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}


const pagesService = {
  getPage
}

export default pagesService