import React, { useState } from 'react';
import { useTranslation } from "../../../hooks/useTranslation";
import { useForm } from "react-hook-form";
import { CommonBtn } from "../../Common/CSTButton";
import Loading from '../../Common/Loading'
import emailService from '../../../services/emailService';

import styles from './style.module.scss'

const ContactForm = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [ response, setResponse ] = useState({})
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    const res = await emailService.sendContactMessage(data)
    setResponse(res);
    setLoading(false)
  }

  return (
    loading ?
      <Loading />
      :
      <div className={styles.contactFormWraper}>
        <div className='container'>
          <h3 className="mb-4">{t('Make a Request')}</h3>
          {response.success && response.result && <div className='alert alert-success' role='alert'>{t('You have sent contact message to admin!')}</div>}
          {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <input className="form-control" type="text" placeholder={t('Enter Full Name')}
                  {...register("name", { required: `${t('Field is required')}` })}
                />
                <div className="text-danger">{errors?.name?.message}</div>
              </div>
              <div className="col-lg-6 mb-3">
                <input className="form-control" type="email" placeholder={t('Email address')}
                  {...register("email", { required: `${t('Field is required')}` })}
                />
                <div className="text-danger">{errors?.email?.message}</div>
              </div>
              <div className="col-lg-6 mb-3">
                <input className="form-control" type="text" placeholder={t('Phone number')}
                  onKeyPress={(e) => {
                    if (!/^[0-9.]$/.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  {...register("phone", { required: `${t('Field is required')}` })}
                />
                <div className="text-danger">{errors?.phone?.message}</div>
              </div>
              <div className="col-lg-6 mb-3">
                <input className="form-control" type="text" placeholder={t('Subject')}
                  {...register("subject", { required: `${t('Field is required')}` })}
                />
                <div className="text-danger">{errors?.subject?.message}</div>
              </div>
              <div className="col-lg-12 mb-3">
                <textarea className="form-control" placeholder={t('Enter message')} rows="5"
                  {...register("content", { required: `${t('Field is required')}` })}
                >
                </textarea>
                <div className="text-danger">{errors?.content?.message}</div>
              </div>
            </div>
            <div className="text-center">
              <CommonBtn color="success" type="submit" uppercase borderRadius
                ariaLabel={t('Submit Request')}
              >
                {t('Submit Request')}
              </CommonBtn>
            </div>
          </form>
        </div>
      </div>
  )
}

export default ContactForm;


