import { HelmetTags } from '../../components/Common/HelmetTags'
import PageContainer from '../../components/BlogList/PageContainer'

const BlogList = () => {
  return (
    <>
      <HelmetTags
        title="News"
      />
      <PageContainer/>
    </>
  )
}

export default BlogList;

