import React, {useState} from 'react';
import { useTranslation } from "../../../hooks/useTranslation"
import { CommonBtn } from "../../Common/CSTButton"
import DetailSearch from "./DetailSearch";
import DoctorSearch from "./DoctorSearch";

import styles from './style.module.scss'

const HomeSearch = (props) => {
  const {searchData} = props
  const { t } = useTranslation();
  const [tab, setTab] = useState('first')

  return (
    <div className={styles.homeSearchContainer}>
      <div className="container">
        <div className={styles.searchWraper}>
          <div className={styles.btnTab}>
            <div className={tab === 'first' ? `${styles.active}` : ``}>
              <CommonBtn size="small" uppercase
                ariaLabel={t('Find & book a doctor')}
                onClick={()=>setTab('first')}
              >
                {t('Find & book a doctor')}
              </CommonBtn>
            </div>
            <div className={tab === 'second' ? `${styles.active}` : ``}>
              <CommonBtn size="small" uppercase
                ariaLabel={t('Evaluate doctor')}
                onClick={()=>setTab('second')}
              >
                {t('Evaluate doctor')}
              </CommonBtn>
            </div>
          </div>
          {tab === 'first' && <DetailSearch searchData={searchData}/> }
          {tab === 'second' && <DoctorSearch /> }
        </div>
      </div>
    </div>
  );
}

export default HomeSearch;