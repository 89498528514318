import { useTranslation } from "../../../../hooks/useTranslation";
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../../../../store/auth";
import { BiUserCircle } from 'react-icons/bi'
import { RiErrorWarningLine } from 'react-icons/ri'
import { AiOutlineClose } from "react-icons/ai"
import { FaRegCalendarAlt, FaEye, FaRegStar, FaRegBell, FaUserTie, FaSignOutAlt } from "react-icons/fa";
import AsideAccodian from './AsideAccodian'
import { menuList } from '../../../../utilities/constants'
import { backendUrl } from "../../../../utilities/constants";

import styles from './style.module.scss'

const Aside = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { handleClose, isSidebarOpen } = props
  const { auth } = useSelector(store => store.auth)

  const signOut = () => {
    dispatch(userLogout())
    handleClose()
  }

  return (
    <>
      <div className={`${styles.asideOverlay} ${isSidebarOpen ? styles.active : ''}`}></div>
      <div className={`${styles.asideContainer} ${isSidebarOpen ? styles.active : ''}`}>

        <div className={styles.asideClose} onClick={handleClose}>
          <AiOutlineClose size="30" />
        </div>

        <Link to='/'>
          <div className={styles.asideLogoContainer} onClick={handleClose}>
            <img
              src={backendUrl + '/images/site_logo.png?v=' + new Date().getTime()}
              alt={t('Site Logo')}
              className={styles.asideLogo}
            />
          </div>
        </Link>

        <ul className={styles.navbarNav}>
          {auth?.token ?
            <li className={styles.actionLinks}>
              <Link to={auth?.role === 'patient' ? '/patient' : '/doctor'} onClick={handleClose}>
                <BiUserCircle size="24" className="me-2" />
                {auth?.email}
              </Link>
            </li>
            :
            <>
              <li className={styles.actionLinks}>
                <Link to="/doctor" onClick={handleClose}>
                  <RiErrorWarningLine size="24" className="me-2" />
                  {t('Are you a doctor?')}
                </Link>
              </li>
              <li className={styles.actionLinks}>
                <Link to="/login" onClick={handleClose}>
                  <BiUserCircle size="24" className="me-2" />
                  {`${t('Login')} / ${'Registration'}`}
                </Link>
              </li>
            </>
          }

          {auth?.role === 'patient' &&
            <>
              <li className={styles.actionLinks}>
                <Link to="/patient/dates" onClick={handleClose}>
                  <FaRegCalendarAlt size="20" className="me-2" />
                  {t('My dates')}
                </Link>
              </li>
              <li className={styles.actionLinks}>
                <Link to="/patient/observed" onClick={handleClose}>
                  <FaEye size="20" className="me-2" />
                  {t('Observed practitioners')}
                </Link>
              </li>
              <li className={styles.actionLinks}>
                <Link to="/patient/reviews" onClick={handleClose}>
                  <FaRegStar size="20" className="me-2" />
                  {t('My reviews')}
                </Link>
              </li>
              <li className={styles.actionLinks}>
                <Link to="/patient/news" onClick={handleClose}>
                  <FaRegBell size="20" className="me-2" />
                  {t('News')}
                </Link>
              </li>
              <li className={styles.actionLinks}>
                <Link to="/patient/account" onClick={handleClose}>
                  <FaUserTie size="20" className="me-2" />
                  {t('My account')}
                </Link>
              </li>
              <li className={styles.actionLinks}>
                <Link to="/" onClick={signOut}>
                  <FaSignOutAlt size="20" className="me-2" />
                  {t('Log out')}
                </Link>
              </li>
            </>
          }

          <li className={styles.spaceLi}> </li>

          {menuList.map((item, index) => (
            <AsideAccodian key={index} item={item} handleClose={handleClose} />
          ))}

        </ul>

      </div >
    </>
  );
}

export default Aside;