import { HelmetTags } from '../../components/Common/HelmetTags'
import PageContainer from '../../components/AboutUs/PageContainer'

const AboutUs = () => {
  return (
    <>
      <HelmetTags
        title="About Us"
      />
      <PageContainer/>
    </>
  )
}

export default AboutUs;

