import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Register from "./Register";
import Result from "./Register/Result";
import Payment from "./Register/Payment";

const Doctor = () => {
  return (
    <div className="container userMain">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/result" element={<Result />} />
        <Route path="/register/payment" element={<Payment />} />
      </Routes>
    </div>
  );
}

export default Doctor;