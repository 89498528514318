import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "../../../hooks/useTranslation";
import { CommonBtn } from "../../Common/CSTButton"
import { BsSearch } from "react-icons/bs";
import { BiCalendar } from "react-icons/bi";
import NameSearch from './NameSearch';
import LocationSearch from './LocationSearch';

import styles from './style.module.scss'

const DetailSearch = (props) => {
  const {searchData} = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [searchRequest, setSearchRequest] = useState(searchData || {
    nameCt: '',
    nameValue: '',
    locationCt: '',
    locationValue: '',
    booking_method: 0,
  })

  useEffect(()=>{
    if(searchData) setSearchRequest(searchData)
  }, [searchData])

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/doctor-list", {state: searchRequest});
  }

  const changeBookingMethod = (e) => {
    setSearchRequest({
      ...searchRequest,
      booking_method: e.target.value
    })
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <div className={`${styles.nomargin} row`}>

        <div className={`${styles.nopadding} col-lg-3`}>
          <NameSearch searchRequest={searchRequest} setSearchRequest={setSearchRequest}/>
        </div>

        <div className={`${styles.nopadding} col-lg-3`}>
          <LocationSearch searchRequest={searchRequest} setSearchRequest={setSearchRequest}/>
        </div>

        <div className={`${styles.nopadding} col-lg-6`}>
          <div className="form-group">
            <div className="input-group">
              <div className="flex-1 d-flex">
                <BiCalendar size="18" className="inIcon leftIcon" />
                <select 
                  className="form-control prevIcon"
                  onChange={changeBookingMethod}
                >
                  <option value="0">{t('Bookable by phone / online')}</option>
                  <option value="1">{t('Only bookable online')}</option>
                </select>
              </div>
              <CommonBtn color="primary" size="small" type="submit" uppercase
                ariaLabel={t('Find Now')}
              >
                <BsSearch size="16" />
                <div className="ms-1">
                  {t('Find Now')}
                </div>
              </CommonBtn>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default DetailSearch;