import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';
import authService from '../../../../services/authService';

const PpButton = (props) => {
  const { doctor, totalPrice, configs, setLoading, setResponse } = props
  const navigate = useNavigate()

  const initialOptions = {
    "client-id": configs?.paypal_clientId,
    currency: configs?.currency_name
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: totalPrice,
          },
        },
      ],
    });
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      userRegister();
    });
  }

  const userRegister = async () => {
    setLoading(true)
    const data = doctor?.user
    data.status = 0
    data.transaction = {
      status: 1,
      product: doctor?.doctorType?.name,
      payment_method: 'paypal',
      price: totalPrice
    }
    const response = await authService.userRegister(data)
    if (response.success && response.result) {
      navigate("/doctor/register/result", { state: { ...doctor, res: response } });
    } else {
      setResponse(response)
    }
    setLoading(false)
  }

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        style={{
          layout: "vertical",
          color: 'blue'
        }}
        fundingSource="paypal"
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </PayPalScriptProvider>
  );
}

export default PpButton;