import { Link } from 'react-router-dom'
import { useTranslation } from '../../../hooks/useTranslation';

import styles from './style.module.scss'

const MyReviews = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.MydatesContainer}>
      <div className='d-flex align-items-center justify-content-between'>
        <h2>{t('My reviews')}</h2>
        <Link to='/'>{t('Rate doctor')}</Link>
      </div>
      <div className={styles.itemRow}>
        <img src='./images/img/review-vector.jpg' alt="Review" />
        <div>
          {t('Help other patients to find the right doctor and share your experience.')}
        </div>
      </div>
    </div>
  );
}

export default MyReviews;