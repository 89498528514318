import { useSelector } from 'react-redux';
import styles from './style.module.scss'

const AboutMe = () => {
  const {auth} = useSelector(store=>store.auth)
  return (  
    <div className={styles.aboutContainer}>
      {auth?.about_me}
    </div>
  );
}
 
export default AboutMe;