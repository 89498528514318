import React, { useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCoordinates } from '../../../hooks/googleMap';
import { CommonBtn } from '../../Common/CSTButton';
import TitleDropdown from '../../Common/TitleDropdown'
import ExpertiseDropdown from '../../Common/ExpertiseDropdown'
import CountryDropdown from '../../Common/CountryDropdown'
import GroupDropdown from '../../Common/GroupDropdown';
import OpentHours from '../../Common/OpentHours';
import authService from '../../../services/authService'
import Loading from '../../Common/Loading'

const PageContainer = (props) => {
  const { doctorType } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [ response, setResponse ] = useState({})
  const configs = useSelector(store => store.configs.configs);
  const [loading, setLoading] = useState(false)
  const { register, setValue, getValues, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      open_hours: {},
    }
  });

  const onSubmit = async (data) => {
    setLoading(true)
    data.timezone = data.timezone.value;
    data.open_hours = JSON.stringify(data.open_hours)
    data.doctor_type_id = doctorType?.id
    data.role = "doctor"
    const address = data.city + ', ' + data.zip;
    const cood = await getCoordinates(address, configs.google_map_api)
    data.lat = cood?.lat
    data.lng = cood?.lng
    
    if(doctorType?.price>0){
      const res = await authService.checkEmail(data)
      if(res.success && res.result){
        const doctor = {user: data, doctorType: doctorType}
        navigate("./payment", {state: doctor});
      } else {
        setResponse(res)
      }
    } else {
      data.status = 0;
      const res = await authService.userRegister(data)
      if(res.success && res.result){
        const doctor = {user: data, res: res, doctorType: doctorType}
        navigate("./result", {state: doctor});
      } else{
        setResponse(res)
      }
    }
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <form onSubmit={handleSubmit(onSubmit)}>
        <br />
        {!response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div>}
        <div className="row mb-2">
          <div className="col-lg-3 col-md-4 col-4">
            <label className="text-end d-block">
              {t('Gender')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8 col-8">
            <div className="d-flex">
              <div className="me-3">
                <input type="radio" id="female" value="female"
                  {...register("gender", { required: `${t('Field is required')}` })}
                />
                <label htmlFor="female">
                  {t('Female')}
                </label>
              </div>
              <div className="me-2">
                <input type="radio" id="male" value="male"
                  {...register("gender", { required: `${t('Field is required')}` })}
                />
                <label htmlFor="male">
                  {t('Male')}
                </label>
              </div>
            </div>
            <div className="text-danger">{errors?.gender?.message}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Your Title')} :
            </label>
          </div>
          <div className="col-lg-3 col-md-4 mb-2">
            <Controller
              name='title_id'
              control={control}
              render={({
                field: { value, onChange }
              }) => (
                <TitleDropdown
                  selected={value}
                  onChange={onChange}
                />
              )}
              rules={{ required: t('Field is required') }}
            />
            <div className="text-danger">{errors?.title_id?.message}</div>
          </div>
          <div className="col-lg-3 col-md-4 mb-2">
            <Controller
              name='expertise_id'
              control={control}
              render={({
                field: { value, onChange }
              }) => (
                <ExpertiseDropdown
                  selected={value}
                  onChange={onChange}
                />
              )}
              rules={{ required: t('Field is required') }}
            />
            <div className="text-danger">{errors?.expertise_id?.message}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('First Name')} :
            </label>
          </div>
          <div className="col-lg-2 col-md-8 mb-2">
            <input className="form-control smInput" type="text"
              {...register("first_name", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.first_name?.message}</div>
          </div>
          <div className="col-lg-2 col-md-4">
            <label className="form-label">
              {t('Last Name')} :
            </label>
          </div>
          <div className="col-lg-2 col-md-8 mb-2">
            <input className="form-control smInput" type="text"
              {...register("last_name", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.last_name?.message}</div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Surgery')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="text"
              {...register("praxis", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.praxis?.message}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Country')} :
            </label>
          </div>
          <div className="col-lg-3 col-md-8 mb-2">
            <Controller
              name='country_id'
              control={control}
              render={({
                field: { value, onChange }
              }) => (
                <CountryDropdown
                  selected={value}
                  onChange={onChange}
                />
              )}
              rules={{ required: t('Field is required') }}
            />
            <div className="text-danger">{errors?.country_id?.message}</div>
          </div>

          <div className="col-lg-1 col-md-4">
            <label className="form-label">
              {t('State')} :
            </label>
          </div>
          <div className="col-lg-2 col-md-8 mb-2">
            <input className="form-control smInput" type="text"
              {...register("state", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.state?.message}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Post code') + '/' + t('City')} :
            </label>
          </div>
          <div className="col-lg-2 col-md-3 mb-2">
            <input className="form-control smInput" type="text"
              {...register("zip", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.zip?.message}</div>
          </div>
          <div className="col-lg-4 col-md-5 mb-2">
            <input className="form-control smInput" type="text"
              {...register("city", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.city?.message}</div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Street No.')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="text"
              {...register("street")}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Phone')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="text"
              onKeyPress={(e) => {
                if (!/^[0-9.]$/.test(e.key)) {
                  e.preventDefault()
                }
              }}
              {...register("phone")}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Fax')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="text"
              {...register("fax")}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Website')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="text"
              {...register("web")}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Email address')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="email"
              {...register("email", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.email?.message}</div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label" htmlFor="password">
              {`${t('Your')} ${t('Password')}`} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="password" id="password"
              {...register("password", {
                required: `${t('Field is required')}`,
                minLength: {
                  value: 6,
                  message: `${t('Password requires at least 6 charactors')}`
                }
              })}
            />
            <div className="text-danger">{errors?.password?.message}</div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label" htmlFor="c_password">
              {t('Wiederholung Ihres Passwortes')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <input className="form-control smInput" type="password" id="c_password"
              {...register("c_password", {
                required: `${t('Field is required')}`,
                minLength: {
                  value: 6,
                  message: `${t('Password requires at least 6 charactors')}`
                },
                validate: value =>
                  value === getValues('password') || t('The-match')
              })}
            />
            <div className="text-danger">{errors?.c_password?.message}</div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Group')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <Controller
              name='group_id'
              control={control}
              render={({
                field: { value, onChange }
              }) => (
                <GroupDropdown
                  selected={value}
                  onChange={onChange}
                />
              )}
              rules={{ required: t('Field is required') }}
            />
            <div className="text-danger">{errors?.group_id?.message}</div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-3 col-md-4">
            <label className="form-label">
              {t('Open Hours')} :
            </label>
          </div>
          <div className="col-lg-6 col-md-8">
            <OpentHours
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              control={control}
            />
          </div>
        </div>

        <div className="text-center">
          <CommonBtn color="success" type="submit" uppercase borderRadius size="small"
            ariaLabel="register"
          >
            {t('Register')}
          </CommonBtn>
        </div>
      </form>
  )
}

export default PageContainer;