import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { backendUrl } from '../utilities/constants';
import axios from 'axios'

const currentLng = localStorage.getItem('i18nextLng') || 'de';

export const getTexts = createAsyncThunk(
  'texts/getTexts',
  async () => {
    try {
      const response = await axios.get(backendUrl + '/api/get_texts/'+currentLng)
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const textsSlice = createSlice({
  name: 'texts',
  initialState: {
    texts: [],
    loading: false,
    error: false,
    message: ''
  },

  extraReducers: {

    // ========== fetch All ==================================
    [getTexts.pending]: (state, action) => {
      state.loading = true
    },
    [getTexts.fulfilled]: (state, action) => {
      state.error = false
      state.message = ''
      state.loading = false
      state.texts = action.payload;
    },
    [getTexts.rejected]: (state, action) => {
      state.error = true
      state.message = 'faild'
      state.loading = false
    },
  },
});

export default textsSlice.reducer;
