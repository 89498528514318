import { useTranslation } from "../../../hooks/useTranslation"
import { useSelector } from "react-redux";
import TeamSlider from "./TeamSlider";

import styles from './style.module.scss'

const OurTeam = () => {
  const { t } = useTranslation();
  const doctors = useSelector(store => store.doctors.items)

  return (
    doctors?.length > 0 &&
    <>
      <div className={styles.ourteamContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.smallTitle}>{t('Meet our team')}</div>
          <h3>{t('Our creative team')}</h3>
        </div>
        <div className={styles.teamSliderWraper}>
          <TeamSlider doctors={doctors} />
        </div>
      </div>
      <div className={styles.bottomSpace}></div>
    </>
  )
}

export default OurTeam;