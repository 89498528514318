import { useTranslation } from "../../../../hooks/useTranslation"
import React, { useState } from 'react';
import { BsSearch } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai"

import styles from './style.module.scss'

const HeaderSearch = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Clicked submit');
  }

  const handleOpen = () => {
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className={styles.searchContainer}>
        <BsSearch size="22" color="white" onClick={handleOpen} />
      </div>
      <div className={`${styles.modalContainer} ${isModalOpen ? styles.open: ''}`}>
        <div className={styles.modalClose} onClick={handleClose}>
          <AiOutlineClose size="30" />
        </div>

        <form className={styles.searchForm} onSubmit={handleSubmit}>
          <input type="text" placeholder={`${t('Search')}...`} required=""/>
          <button type="submit" className={styles.searchBtn}>
            <BsSearch size="40"/>
          </button>
        </form>

      </div>
    </>
  );
}

export default HeaderSearch;