import { useTranslation } from "../../../hooks/useTranslation";
import { useSelector } from 'react-redux';
import { FaCity, FaPhoneVolume } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

import styles from './style.module.scss'

const ContactDetail = () => {
  const {t} = useTranslation()
  const admin = useSelector(store => store.admin.admin);

  return (
    <div className='container'>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className={styles.detailItemWraper}>
            <div className={styles.iconWraper}>
              <FaCity size={30}/>
            </div>
            <div className={styles.detailContentWraper}>
              <div className={styles.detailTitle}>
                {t('Our address')}
              </div>
              <div>
                {`${admin?.city}, ${admin?.zip}`}
                <br/>
                {admin?.country}
              </div>
            </div>  
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className={styles.detailItemWraper}>
            <div className={styles.iconWraper}>
              <FaPhoneVolume size={30}/>
            </div>
            <div className={styles.detailContentWraper}>
              <div className={styles.detailTitle}>
                {t('Our Phone')}
              </div>
              <div>
                {admin?.phone && `${t('Mobile')}: ${admin?.phone}`}
              </div>
            </div>  
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className={styles.detailItemWraper}>
            <div className={styles.iconWraper}>
              <FiMail size={30}/>
            </div>
            <div className={styles.detailContentWraper}>
              <div className={styles.detailTitle}>
                {t('Our Email')}
              </div>
              <div>
                {admin?.email}
              </div>
            </div>  
          </div>
        </div>

      </div>
    </div>
  )
}

export default ContactDetail;


