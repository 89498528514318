import React, { useState } from 'react';
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import DoctorSlideItem from './DoctorSlideItem';
import styles from './style.module.scss'

const TeamSlider = (props) => {
  const { doctors } = props
  const [swiperInstance, setSwiperInstance] = useState(null)
  
  return (
    <div className={styles.sliderWraper}
      onMouseEnter={() => swiperInstance.autoplay.pause()}
      onMouseLeave={() => swiperInstance.autoplay.run()}
    >
      <Swiper
        onInit={(swiper) => setSwiperInstance(swiper)}
        autoplay={{
          delay: 2500,
        }}
        centeredSlides={doctors?.length === 1 ? true : false}
        loop={doctors?.length > 5 ? true : false}
        spaceBetween={20}
        modules={[Autoplay]}
        breakpoints={{
          490: {
            slidesPerView: 2,
          },
          670: {
            slidesPerView: 3,
          },
          920: {
            slidesPerView: 4,
          },
          1100: {
            slidesPerView: 5,
          }
        }}
      >
        {doctors.map(item => (
          <SwiperSlide key={item.id} style={{ height: 'initial' }}>
            <DoctorSlideItem item={item}/>
          </SwiperSlide>
        ))
        }
      </Swiper>
    </div>
  )
}

export default TeamSlider;