import { useTranslation } from '../../hooks/useTranslation';
import PageBackGround from '../Common/PageBackGround';
import ContactDetail from './ContactDetail'
import ContactForm from "./ContactForm";
import AddressMap from "./AddressMap";

const PageContainer = () => {
  const {t} = useTranslation()
  return (
    <>
      <PageBackGround>
        <h1>{t('Contact Us')}</h1>
      </PageBackGround>
      <ContactDetail/>
      <ContactForm/>
      <AddressMap/>
    </>
  )
}

export default PageContainer;


