import { Link } from 'react-router-dom'
import moment from "moment";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { SmallMarkBtn } from '../../Common/CSTButton';
import { backendUrl } from '../../../utilities/constants';
import styles from './style.module.scss'

const BlogItem = (props) => {
  const {item} = props

  return (
    <div className={styles.slideContainer}>
      <div className='position-relative'>
        <Link to={`/blog/${item.slug}`}>
            <div className={styles.imageWraper}>
              <img src={backendUrl + '/images/blogs/' + item.image + '?v=' + new Date().getTime()} alt={item.title} />
            </div>
        </Link>
        <div className={styles.blogDate}>
          <SmallMarkBtn color="success" ariaLabel="Date">
            {moment(new Date(item.created_at)).format('LL')}
          </SmallMarkBtn>
        </div>
      </div>
      <div className={styles.slideContent}>
        <div className={styles.userCat}>
          <Link to={`/expertise`}>
              <div className='d-flex align-items-center'>
                <AiOutlineFolderOpen size="18" />
                {item.expertise}
              </div>
          </Link>
        </div>
        <div className={styles.blogTitle}>
          <Link to={`/blog/${item.id}`}>
            {item.title}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogItem;