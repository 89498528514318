// export const backendUrl = 'http://127.0.0.1:8000';
export const backendUrl = 'https://server.sphp.de';

export const countPerPage ={
  blogs: 5,
  doctors: 3
};

export const menuList = [
  {
    title: 'Home',
    url: '/'
  }, {
    title: 'Pages',
    url: '',
    submenu: [
      { url: '/page/faqs', title: 'FAQs' },
      { url: '/page/terms', title: 'Terms And Agree' },
      { url: '/page/privacy', title: 'Privacy' },
      { url: '/page/help', title: 'Help' },
    ]
  }, {
    title: 'Doctors',
    url: '',
    submenu: [
      { url: '/doctor1', title: 'Doctor1' },
      { url: '/doctor2', title: 'Doctor2' },
      { url: '/doctor3', title: 'Doctor3' },
      { url: '/doctor4', title: 'Doctor4' }
    ]
  }, {
    title: 'Contact Us',
    url: '/contact',
  }
];

export const usefullMenus = [
  { title: 'Home', url: '' },
  { title: 'About Us', url: 'about-us' },
  { title: 'News', url: 'blog-list' },
  { title: 'Doctors', url: 'doctor-list' },
  { title: 'Contact Us', url: 'contact' },
]