import { useSelector } from 'react-redux'
import { useTranslation } from '../../../hooks/useTranslation';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { BsChevronLeft, BsChevronRight, BsPlus } from "react-icons/bs";
import { CommonBtn } from '../../Common/CSTButton';
import { backendUrl } from '../../../utilities/constants';

import styles from './style.module.scss'

const SliderContainer = () => {

  const { t } = useTranslation();
  const { items } = useSelector(store => store.sliders)

  return (
    items?.length > 0
      ? <div className={styles.sliderContainer}>
        <Swiper
          slidesPerView={1}
          modules={[Navigation]}
          initialSlide={2}
          navigation={{
            prevEl: '.sliderPrevBtn',
            nextEl: '.sliderNextBtn',
          }}
          loop={true}
        >
          {items?.map(item => (
            <SwiperSlide key={item.id}>
              <div className={styles.slidWraper}>
                <div className={styles.slidImg}>
                  <img src={backendUrl + '/images/sliders/' + item.image + '?v=' + new Date().getTime()} alt={`slider${item.id}`} />
                </div>
                <div className={styles.sliderContent}>
                  <h5>{item?.title}</h5>
                  <h1>{item?.text}</h1>
                  <div className={styles.btnContainer}>
                    <Link to="/doctor-list">
                      <CommonBtn color="success" uppercase borderRadius ariaLabel={t('Find a doctor')}>
                        <div className="me-1">
                          {t('Find a doctor')}
                        </div>
                        <BsPlus size="20" />
                      </CommonBtn>
                    </Link>
                    <Link to="/readmore">
                      <CommonBtn color="white" uppercase borderRadius ariaLabel={t('Read More')}>
                        <div className="me-1">
                          {t('Read More')}
                        </div>
                        <BsPlus size="20" />
                      </CommonBtn>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
          }

          <div className={`${styles.navBtn} ${styles.navPrevBtn} sliderPrevBtn`} >
            <CommonBtn color="success" size="small" borderRadius ariaLabel="Prev">
              <BsChevronLeft size="20" />
            </CommonBtn>
          </div>
          <div className={`${styles.navBtn} ${styles.navNextBtn} sliderNextBtn`} >
            <CommonBtn color="success" size="small" borderRadius ariaLabel="Next">
              <BsChevronRight size="20" />
            </CommonBtn>
          </div>
        </Swiper>
      </div>
      : <div className={styles.noContent}></div>
  )
}

export default SliderContainer;

