import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { Provider } from 'react-redux';
import store from './store';

import App from './App';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['localStorage','htmlTag'],
      caches: ['localStorage']
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback="...loading">
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);