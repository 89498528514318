import React, {useState} from 'react';
import { useForm, Controller } from "react-hook-form";
import moment from 'moment';
import { CommonBtn } from '../../Common/CSTButton'
import { useSelector, useDispatch } from 'react-redux';
import { updateAuth } from "../../../store/auth";
import authService from '../../../services/authService';
import DatePicker from "react-datepicker";
import { useTranslation } from '../../../hooks/useTranslation';
import PartLoading from "../../Common/PartLoading";
import "react-datepicker/dist/react-datepicker.css";
import styles from './style.module.scss'

const Basic = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})
  const { auth } = useSelector(store => store.auth)
  const { handleSubmit, register, control, formState: { errors } } = useForm({
    defaultValues: {
      gender: auth?.gender ? auth?.gender : '',
      birthday: auth?.birthday ? new Date(auth?.birthday) : ''
    }
  })

  const submitData = async (data) => {
    setLoading(true)
    const res = await authService.userUpdate(data);
    dispatch(updateAuth(res.result));
    setResponse(res)
    setLoading(false)
  }

  const onSubmit = (data) => {
    data.birthday = data?.birthday ? moment(data?.birthday).format('yyyy-MM-DD') : '';
    data.id = auth?.id
    data.token = auth?.token
    submitData(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      {loading && <PartLoading/> }
      <div className={styles.formTitle}>
        {t('Change Basic data')}
      </div>
      { !response.success && response.result && <div className='alert alert-danger' role='alert'>{t(response.result)}</div> }
      { response.success && response.result && <div className='alert alert-success' role='alert'>{t('Changing is successed')}</div> }
      <div className='row mb-2'>
        <div className='col-lg-2 col-sm-3 col-12'>
          <label className="ps-0">{t('Gender')}: </label>
        </div>
        <div className='col-sm-9 col-12'>
          <div className="d-flex">
            <div className="me-3">
              <input type="radio" id="female" value="female"
                {...register("gender", { required: `${t('Field is required')}` })}
              />
              <label htmlFor="female">
                {t('Female')}
              </label>
            </div>
            <div className="me-2">
              <input type="radio" id="male" value="male"
                {...register("gender", { required: `${t('Field is required')}` })}
              />
              <label htmlFor="male">
                {t('Male')}
              </label>
            </div>
            <div className="text-danger">{errors?.gender?.message}</div>
          </div>
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-lg-2 col-md-3 col-sm-3 col-12'>
          <label className="text-start form-label">{t('Birthday')}: </label>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-8 col-12'>
          <Controller
            name='birthday'
            control={control}
            render={({
              field: { value, onChange }
            }) => (
              <DatePicker className="form-control smInput"
                selected={value}
                onChange={onChange}
                dateFormat="dd-MM-yyyy"
              />
            )}
            rules={{ required: t('Field is required') }}
          />
          <div className="text-danger">{errors?.birthday?.message}</div>
        </div>
      </div>
      <CommonBtn type="submit" color="primary" borderRadius size="small">
        {t('Change Basic data')}
      </CommonBtn>
    </form>
  );
}

export default Basic;