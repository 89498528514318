import axios from 'axios'
import { backendUrl } from '../utilities/constants';

const stripePay = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/stripe_pay', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}


const paymentService = {
  stripePay
}

export default paymentService