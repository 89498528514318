import { useTranslation } from '../../../hooks/useTranslation';
import CurrentAppointments from './CurrentAppointments'
import PastAppointments from './PastAppointments'
import styles from './style.module.scss'

const PageContainer = () => {
  const {t} = useTranslation()
  return (
    <div className={styles.homeContainer}>
      <h1>{t('My appointment bookings')}</h1>
      <CurrentAppointments/>
      <PastAppointments/>
    </div>
  );
}

export default PageContainer;