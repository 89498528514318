import React, {useState, useEffect} from 'react';
import { useTranslation } from "../../hooks/useTranslation";
import PageBackGround from '../Common/PageBackGround';
import { useParams } from "react-router-dom";
import pagesService from '../../services/pagesService';

const PageContainer = () => {
  const {t} = useTranslation()
  const { page } = useParams();
  const [content, setContent] = useState('')

  const getPage = async () => {
    const res = await pagesService.getPage(page);
    setContent(res?.result?.text)
  }

  useEffect(()=>{
    getPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page])

  return (
    <>
      <PageBackGround>
        <h1>{t(page.charAt(0).toUpperCase() + page.slice(1))}</h1>
      </PageBackGround>
      <div className='container'
        dangerouslySetInnerHTML={{__html: content}}
      >
      </div>
    </>
  )
}

export default PageContainer;

