import { useTranslation } from "../../../hooks/useTranslation"
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { backendUrl } from "../../../utilities/constants";

import styles from './style.module.scss'


const Slider = (props) => {
  const { t } = useTranslation();
  const {services} = props
  const [swiperInstance, setSwiperInstance] = useState(null)

  return (
    <div className={styles.sliderWraper}
      onMouseEnter={() => swiperInstance?.autoplay?.pause()}
      onMouseLeave={() => swiperInstance?.autoplay?.run()}
    >
      <Swiper
        onInit={(swiper) => setSwiperInstance(swiper)}
        autoplay={{
          delay: 3000,
        }}
        centeredSlides={services?.length === 1 ? true : false}
        loop={services?.length > 3 ? true : false}
        modules={[Autoplay]}
        breakpoints={{
          576: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          993: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }}
      >
        {services.map(item => (
          <SwiperSlide key={item.id} style={{ height: 'initial' }}>
            <div className={styles.slideContainer}>
              <div className={styles.imageWraper}>
                <img src={backendUrl + '/images/services/' + item.image + '?v=' + new Date().getTime()} alt={item.title_en} />
              </div>
              <div className={styles.slideContent}>
                <div className={styles.contentTitle}>
                  <Link to={`/service/${item.slug}`}>
                    {item.title}
                  </Link>
                </div>
                <div className={styles.contenMain}>
                  {item.description}
                </div>
              </div>
              <div className={styles.readMore}>
                <Link to={`/service/${item.slug}`}>
                  <div className="d-flex align-items-center">
                    <div className="me-2">
                      {t('Read More')}
                    </div>
                    <HiArrowNarrowRight />
                  </div>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))
        }
      </Swiper>
    </div>

  );
}

export default Slider;