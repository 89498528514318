import { HelmetTags } from '../../../components/Common/HelmetTags'
import PageContainer from '../../../components/Patient/Home/PageContainer'
import CheckLoggedin from '../../../components/Patient/Auth/CheckLoggedin'

const Home = () => {
  return (
    <CheckLoggedin>
      <HelmetTags
        title="Patient"
      />
      <PageContainer/>
    </CheckLoggedin>
  )
}

export default Home;

