import { useTranslation } from '../../../hooks/useTranslation';
import Basic from './Basic'
import EmailChanging from './EmailChanging'
import PasswordChange from './PasswordChange'
import Notifications from './Notifications'
import DeleteAccount from './DeleteAccount';
import styles from './style.module.scss'

const PageContainer = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.accountContainer}>
      <h1>{t('My account')}</h1>
      <Basic/>
      <EmailChanging/>
      <PasswordChange/>
      <Notifications/>
      <DeleteAccount/>
    </div>
  );
}

export default PageContainer;